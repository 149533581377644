<div class="mt-1 mr-2 ml-auto">	
	<select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
		<option [value]="lang.code" *ngFor="let lang of getLangs()">{{lang.text}}</option>
	</select>
</div>
<div class="app-body">
	<main class="main d-flex align-items-center login-page">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 mx-auto">
					<div class="card-group">
						<div class="card p-4">
							<div class="card-body">
								<form [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
									<h1>{{'login.title' | translate }}</h1>
									<p class="text-muted">{{'login.Sign_In_to_your_account' | translate }}</p>
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="icon-user"></i></span>
										</div>
										<input class="form-control" formControlName="email"
											placeholder="{{'login.email.placeholder' | translate }}" autocomplete="off"
											trim="blur" type="text" />
										<div class="error"
											*ngIf="(submitted && getControls.email.invalid) || getControls.email.touched">
											<div *ngIf="getControls.email.errors?.required;else checkPattern"
												class="err-msg">{{'login.email.validation' | translate }}</div>
											<ng-template #checkPattern>
												<div *ngIf="getControls.email.errors?.pattern" class="err-msg">
													{{'login.email.validation' | translate }}</div>
											</ng-template>
										</div>
									</div>
									<div class="input-group mb-4">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="icon-lock"></i></span>
										</div>
										<input type="password" class="form-control" formControlName="password"
											placeholder="{{'login.password.placeholder' | translate }}"
											autocomplete="off" />
										<div class="error"
											*ngIf="(submitted && getControls.password.invalid) || getControls.password.touched">
											<div *ngIf="getControls.password.errors?.required;else checkMinMaxLength"
												class="err-msg">{{'login.password.validation.required' | translate }}
											</div>
											<ng-template #checkMinMaxLength>
												<div *ngIf="(getControls.password.errors?.minlength || getControls.password.errors?.maxlength) ; else checkWhitespace"
													class="err-msg">Password must be between 6 to 20 characters.</div>
											</ng-template>
											<ng-template #checkWhitespace>
												<div *ngIf="getControls.password.errors?.pattern" class="err-msg">
													{{'login.password.validation' | translate }}</div>
											</ng-template>
										</div>

									</div>
									<div class="row">
										<div class="col-6"> <button type="submit"
												class="btn btn-primary px-4">{{'login.login.button' | translate
												}}</button></div>
										<div class="col-6 text-right forgot-password-text">
											<a [routerLink]="['/forgot-password']"
												class="btn btn-link px-0">{{'login.forgot_password.link' | translate
												}}</a>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="card text-white bg-primary py-5">
							<div class="card-body text-center">
								<div>
									<h2>{{'login.signup' | translate }}</h2>
									<p>{{'login.easyR.info' | translate}}</p>
									<a [routerLink]="['/register']"
										class="btn btn-primary active mt-3">{{'login.button.registerNow' | translate
										}}</a>
										<span class="d-block mt-4"> {{'video_text.signup_video.text' | translate }}
										<button type="button" class="btn play-btn-up" (click)="openModal(template)">
											{{'video_text.watch_video.text' | translate }} <img src="assets/play-button.png"> 
										</button>	
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</div>
<!-- Modal -->
	<!-- <ng-template #template>
		<button type="button" class="close close-btn-2" data-dismiss="modal" aria-label="Close" (click)="modalRef?.hide()">
			<span aria-hidden="true">&times;</span>
		  </button>
	<div class="modal-body">
		<div class="video-box">
			<video controls style="width:100%;min-height:500px;display:block" >
				<source *ngIf="selectedLanguage == 'en'" src="assets/video/signup_english.mov" type="video/mp4">
				<source *ngIf="selectedLanguage == 'da'" src="assets/video/signup_danish.mov" type="video/mp4">
				<source *ngIf="selectedLanguage == 'ar'" src="assets/video/signup_english.mov" type="video/mp4">
				Your browser does not support the video.
			  </video>		</div>
	</div>
	</ng-template> -->
	<ng-template #template>
		<button type="button" class="close close-btn-2" data-dismiss="modal" aria-label="Close" (click)="modalRef?.hide()">
		  <span aria-hidden="true">&times;</span>
		  </button>
	  <div class="modal-body">
		<div class="video-box" *ngIf="selectedLanguage == 'en'">
		  <iframe width="100%" height="100%"  src="https://www.youtube.com/embed/4DSvSQlg7Vc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>
		<div class="video-box" *ngIf="selectedLanguage == 'ar'">
		  <iframe width="100%" height="100%"  src="https://www.youtube.com/embed/4DSvSQlg7Vc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>
		<div class="video-box" *ngIf="selectedLanguage == 'da'">
		  <iframe width="100%" height="100%"  src="https://www.youtube.com/embed/5oze0zZZ80U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>
	  </div>
	  </ng-template>