import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/platform-browser";
var TranslatorService = /** @class */ (function () {
    function TranslatorService(translate, titleService) {
        this.translate = translate;
        this.titleService = titleService;
        this.availablelangs = [
            { code: "en", text: "English" },
            { code: "da", text: "Dansk" },
            { code: "ar", text: "Arabic" }
        ];
    }
    TranslatorService.prototype.getAvailableLanguages = function () {
        return this.availablelangs;
    };
    TranslatorService.prototype.traslateTabTitle = function (titleFromRoutes) {
        var _this = this;
        var selected_language = localStorage.getItem("selectedLanguage");
        this.translate.use(selected_language);
        this.translate.get(titleFromRoutes).subscribe(function (res) {
            _this.titleService.setTitle(res);
        });
    };
    TranslatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslatorService_Factory() { return new TranslatorService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.Title)); }, token: TranslatorService, providedIn: "root" });
    return TranslatorService;
}());
export { TranslatorService };
