import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { NgxPermissionsService } from "ngx-permissions";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-permissions";
var AdminService = /** @class */ (function () {
    function AdminService(http, permissionsService) {
        this.http = http;
        this.permissionsService = permissionsService;
        this.api_url = environment.apiUrl + "admin/";
    }
    AdminService.prototype.register = function (data) {
        return this.http.post(environment.apiUrl + "/auth/signup", data);
    };
    /**
     * This method used for get list of admin user with filter, search and sorting
     * @param param : param for filter, search ans sorting
     */
    AdminService.prototype.getList = function (param) {
        return this.http.post(this.api_url + "list", param).pipe(map(function (res) {
            return res["data"];
        }));
    };
    /**
     * This method used for call http for add admin user
     * @param data : detail of admin user with permission list
     */
    AdminService.prototype.add = function (data) {
        return this.http.post(this.api_url + "add", data);
    };
    /**
     * This method used for call http for get meta data.
     * @param data : detail of admin user with permission list
     */
    AdminService.prototype.getMetaData = function (data) {
        return this.http.get(environment.apiUrl + "auth/metaData");
    };
    /**
     * This method is used for forgot password email link send
     */
    AdminService.prototype.ForgotPassword = function (data) {
        return this.http.post(environment.apiUrl + "auth/forgotPassword", data);
    };
    /**
     * This method used for call http for update admin user
     * @param data : updated detail of admin user
     */
    AdminService.prototype.update = function (data) {
        return this.http.post(this.api_url + "update", data);
    };
    /**
     * This method used for call http for delete admin user
     * @param admin_id : admin_id which we want to delete
     */
    AdminService.prototype.delete = function (admin_id) {
        var data = { admin_id: admin_id };
        return this.http.post(this.api_url + "deleted", data);
    };
    /**
     * Change user status as active or in active
     * @param data
     */
    AdminService.prototype.status_change = function (data) {
        return this.http.post(this.api_url + "status", data);
    };
    /**
     * This method used for get admin detail by admin id
     * @param admin_id
     */
    AdminService.prototype.getDetails = function (admin_id) {
        return this.http
            .get(this.api_url + "detailsbyId?admin_id=" + admin_id)
            .pipe(map(function (res) {
            return res["data"];
        }));
    };
    /**
     * This method used for get permission list of login user
     */
    AdminService.prototype.getPermissionList = function () {
        return this.http.get(this.api_url + "permissions").pipe(map(function (res) {
            return res["data"];
        }));
    };
    /**
     * This method used for call http to change password of login user
     * @param data : updated detail of admin user
     */
    AdminService.prototype.changePassword = function (data) {
        return this.http.post(environment.apiUrl + "api/change_password", data);
    };
    AdminService.prototype.getAdminProfile = function (data) {
        return this.http.post(environment.apiUrl + "api/profile", data);
    };
    AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NgxPermissionsService)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
