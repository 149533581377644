/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i3 from "ngx-spinner";
import * as i4 from "./app.component";
import * as i5 from "./shared/services/messaging.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "ngx-spinner", [["bdColor", "rgba(0, 0, 0, 0.8)"], ["color", "#fff"], ["size", "medium"], ["type", "square-jelly-box"]], null, null, null, i2.View_NgxSpinnerComponent_0, i2.RenderType_NgxSpinnerComponent)), i0.ɵdid(3, 770048, null, 0, i3.NgxSpinnerComponent, [i3.NgxSpinnerService, i0.ChangeDetectorRef], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"], fullScreen: [4, "fullScreen"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "p", [["style", "color: white"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Loading... "]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "rgba(0, 0, 0, 0.8)"; var currVal_1 = "medium"; var currVal_2 = "#fff"; var currVal_3 = "square-jelly-box"; var currVal_4 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "body", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i4.AppComponent, [i5.MessagingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("body", i4.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
