import { Injectable } from '@angular/core';
import { AUTH, DEFINE } from '../config/define';
import { Router } from '@angular/router';

import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class CustomInterceptor implements HttpInterceptor {
    constructor(
        public router: Router,
        private loaderService: LoaderService,
        private permissionsService: NgxPermissionsService,
        private toastr: ToastrService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // start spinner 
        this.loaderService.changeValue(true);
        // add authorization header with jwt token if available
        const currentUserToken = localStorage.getItem(AUTH.TOKEN_KEY);
        const currentUser = localStorage.getItem(AUTH.USER_DETAIL);
        let selected_language_code
        if(localStorage.getItem("selectedLanguage"))
        {
            selected_language_code = localStorage.getItem("selectedLanguage")
        }
        else
        {
            selected_language_code = "en"
        }
        let headers = {
            language_code: selected_language_code
        };
        if (currentUser && currentUserToken) {
            headers['Authorization'] = `${currentUserToken}`;
        }
        request = request.clone({
            setHeaders: headers
        });

        // return next.handle(request);
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.loaderService.changeValue(false);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.loaderService.changeValue(false);
                if (error.status == 401) {
                    this.toastr.error(DEFINE.anthorized_msg, 'Error');
                    localStorage.removeItem(AUTH.TOKEN_KEY);
                    localStorage.removeItem(AUTH.USER_DETAIL);
                    this.permissionsService.flushPermissions();
                    this.router.navigate(['/login']);
                }
                return throwError(error);

            }));
    }
}