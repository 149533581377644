import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }
  /**
   * This method is used for admin login
   * @param data : object of email and password of admin user
   */
  adminLogin(data) {
    return this.http.post<any>(`${environment.apiUrl}auth/login`, data);
  }

  /**
   * This method is used for forgot password email link send
   */
  adminForgotLink(data) {
    return this.http.post<any>(`${environment.apiUrl}admin_auth/forgotPassword`, data);
  }

  /**
   * This method is usd for reset password http call
   * @param data : token and new password
   */
  adminResetPassword(data) {
    return this.http.post<any>(`${environment.apiUrl}admin_auth/resetPassword`, data);
  }
}
