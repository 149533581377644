<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="resetPassForm" (ngSubmit)="resetPassFromSubmit()">
                  <h1>Reset Password</h1>
                  <p class="text-muted">Reset Your Password</p>

                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-lock"></i>
                      </span>
                    </div>
                    <input type="password" class="form-control" formControlName="new_password" placeholder="Password" autocomplete="off">
                    <div class="error" *ngIf="(submitted && getControls.new_password.invalid) || getControls.new_password.touched">
                      <div *ngIf="getControls.new_password.errors?.required;else checkMinMaxLength" class="err-msg">Please enter password.</div>
                      <ng-template #checkMinMaxLength>
                        <div *ngIf="(getControls.new_password.errors?.minlength || getControls.new_password.errors?.maxlength) ; else checkWhitespace"
                          class="err-msg">Password must be between 6 to 16 characters.</div>
                      </ng-template>
                      <ng-template #checkWhitespace>
                        <div *ngIf="getControls.new_password.errors?.pattern" class="err-msg">Please enter valid password.</div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-lock"></i>
                      </span>
                    </div>
                    <input type="password" class="form-control" formControlName="confirm_password" placeholder="Confirm Password" autocomplete="off">
                    <div class="error" *ngIf="(submitted && getControls.confirm_password.invalid) || getControls.confirm_password.touched">
                      <div *ngIf="getControls.new_password.errors?.required;else checkMatchPassword" class="err-msg">Please enter confirm password.</div>
                      <ng-template #checkMatchPassword>
                        <div *ngIf="getControls.confirm_password.errors?.MatchPassword" class="err-msg">
                          Confirm password does not match with entered new password.
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary px-4">Submit</button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" class="btn btn-link px-0" [routerLink]="['/login']">Back to login?</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>