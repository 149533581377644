var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true,
};
// Import containers
import { DefaultLayoutComponent } from "./containers";
var APP_CONTAINERS = [DefaultLayoutComponent];
import { HttpClient, } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import 'flatpickr/dist/flatpickr.css';
var ɵ0 = adapterFactory;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
