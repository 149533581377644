import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CompanyService = /** @class */ (function () {
    function CompanyService(http) {
        this.http = http;
        this.api_url = environment.apiUrl + "admin/";
    }
    CompanyService.prototype.addUserDetails = function (data) {
        return this.http.post(environment.apiUrl + "api/add_user_details", data);
    };
    CompanyService.prototype.businessCardDetails = function (data) {
        return this.http.post(environment.apiUrl + "api/business_card_details", data);
    };
    CompanyService.prototype.sendMail = function (data) {
        return this.http.post(environment.apiUrl + "api/send_mail", data);
    };
    CompanyService.prototype.storeLogo = function (fileObj) {
        this.logo_url = fileObj;
    };
    CompanyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.ɵɵinject(i1.HttpClient)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}());
export { CompanyService };
