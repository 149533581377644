import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Admin } from "../models/admin";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NgxPermissionsService } from "ngx-permissions";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private api_url = `${environment.apiUrl}admin/`;
  constructor(
    private http: HttpClient,
    private permissionsService: NgxPermissionsService
  ) {}

  register(data: any) {
    return this.http.post<any>(environment.apiUrl + "/auth/signup", data);
  }
  /**
   * This method used for get list of admin user with filter, search and sorting
   * @param param : param for filter, search ans sorting
   */
  getList(param): Observable<any> {
    return this.http.post(`${this.api_url}list`, param).pipe(
      map((res) => {
        return res["data"];
      })
    );
  }

  /**
   * This method used for call http for add admin user
   * @param data : detail of admin user with permission list
   */
  add(data) {
    return this.http.post(`${this.api_url}add`, data);
  }
  /**
   * This method used for call http for get meta data.
   * @param data : detail of admin user with permission list
   */
  getMetaData(data) {
    return this.http.get<any>(environment.apiUrl + "auth/metaData");
  }
  /**
   * This method is used for forgot password email link send
   */
  ForgotPassword(data) {
    return this.http.post<any>(
      environment.apiUrl + "auth/forgotPassword",
      data
    );
  }

  /**
   * This method used for call http for update admin user
   * @param data : updated detail of admin user
   */
  update(data) {
    return this.http.post(`${this.api_url}update`, data);
  }

  /**
   * This method used for call http for delete admin user
   * @param admin_id : admin_id which we want to delete
   */
  delete(admin_id) {
    let data = { admin_id: admin_id };
    return this.http.post(`${this.api_url}deleted`, data);
  }

  /**
   * Change user status as active or in active
   * @param data
   */
  status_change(data) {
    return this.http.post<any>(`${this.api_url}status`, data);
  }
  /**
   * This method used for get admin detail by admin id
   * @param admin_id
   */
  getDetails(admin_id): Observable<Admin> {
    return this.http
      .get(`${this.api_url}detailsbyId?admin_id=${admin_id}`)
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  /**
   * This method used for get permission list of login user
   */
  getPermissionList() {
    return this.http.get<any>(`${this.api_url}permissions`).pipe(
      map((res) => {
        return res["data"];
      })
    );
  }

  /**
   * This method used for call http to change password of login user
   * @param data : updated detail of admin user
   */
  changePassword(data) {
    return this.http.post<any>(
      environment.apiUrl + "api/change_password",
      data
    );
  }

  getAdminProfile (data) {
    return this.http.post<any>(environment.apiUrl + "api/profile",data);
  }
}
