import { Component, TemplateRef } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { navItemsEnglish,navItemsArabic,navItemsDanish } from "../../_nav";
import { DEFINE, AUTH } from "../../shared/config/define";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { AdminService } from "../../shared/services/admin.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { CLEANING_POSITION } from "../../shared/config/define";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyService } from "../../shared/services/company.service";
import { TranslatorService } from "../../shared/services/translator.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems
  navItemsBasedOnLanguageSelected
  modalRef: BsModalRef;
  businessCardDetails: any = {};
  privateCleaningID: string;
  windowCleaningID: string;
  providedService: any = [];
  isWindowCleaning: Boolean = false;
  isPrivateCleaning: Boolean = false;
  selectedLanguage: string;
  public navItemsFilter;
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  ngOnInit() {
    this.managePermission();
    this.getBusinessCardDetails();
  }
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private translator: TranslatorService,
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private companyService: CompanyService,
    private translate: TranslateService,
    private permissionsService: NgxPermissionsService,
  ) {
    // this.selectedLanguage = this.getLangs()[0].code;
    let getSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (getSelectedLanguage) {
      this.selectedLanguage = getSelectedLanguage;
      this.translate.use(getSelectedLanguage);
      if(this.selectedLanguage == 'ar'){
        let body = document.getElementsByTagName('body')[0];    
        body.classList.add('body-ar');
        this.navItemsBasedOnLanguageSelected = navItemsArabic
      }
      else{
        let body = document.getElementsByTagName('body')[0];    
        body.classList.remove("body-ar");
      }
    } else {
      this.selectedLanguage = environment.default_language;
      this.translate.setDefaultLang(environment.default_language);
    }
    /**
     * Based on selected language change navbar items.
     */
    if(this.selectedLanguage == "en")
    {
      this.navItemsBasedOnLanguageSelected = navItemsEnglish
    }
    if(this.selectedLanguage == "da")
    {
      this.navItemsBasedOnLanguageSelected = navItemsDanish
    }
  }
  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    //this.translator.useLanguage(value);
    this.spinner.show()
    this.translate.use(value);
    this.selectedLanguage = value;
    localStorage.setItem("selectedLanguage", this.selectedLanguage);
    let body = document.getElementsByTagName('body')[0];    
    body.classList.remove("body-ar");
    if(this.selectedLanguage == 'ar'){
      body.classList.add('body-ar');
    }
    else{
      body.classList.remove("body-ar");
    }
    location.reload();
     this.spinner.hide()

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  // manage side bar nav links as per login user permissions
  managePermission() {
    // get permission list of login user which set
    var permissions = this.permissionsService.getPermissions();
    this.permissionsService.permissions$.subscribe((permissions) => {
      const permissionList = Object.keys(permissions);
      this.navItems = this.navItemsBasedOnLanguageSelected.filter((data) => {
          return data.permissions &&
            permissionList.indexOf(data.permissions) == -1
            ? false
            : true;
      });
    });
  }
 
  getMetaData() {
    if (localStorage.getItem("metaData")) {
      let metaData = JSON.parse(localStorage.getItem("metaData"));
      this.windowCleaningID = metaData.windowCleaningID;
      this.privateCleaningID = metaData.privateCleaningID;
      if (
        this.businessCardDetails &&
        this.businessCardDetails.provided_services &&
        this.businessCardDetails.provided_services.length > 0
      ) {
        for (
          let i = 0;
          i < this.businessCardDetails.provided_services.length;
          i++
        ) {
          if (
            this.businessCardDetails.provided_services[i] ==
            this.privateCleaningID
          ) {
            this.providedService.push("Private cleaning");
            this.isPrivateCleaning = true;
          }
          if (
            this.businessCardDetails.provided_services[i] ==
            this.windowCleaningID
          ) {
            this.isWindowCleaning = true;

            this.providedService.push("Window cleaning");
          }
        }
      }
    } else {
      this.adminService.getMetaData({}).subscribe(
        (response) => {
          this.privateCleaningID =
            response.data.services[CLEANING_POSITION[0].index].main._id;
          this.windowCleaningID =
            response.data.services[CLEANING_POSITION[1].index].main._id;
          if (
            this.businessCardDetails &&
            this.businessCardDetails.provided_services &&
            this.businessCardDetails.provided_services.length > 0
          ) {
            for (
              let i = 0;
              i < this.businessCardDetails.provided_services.length;
              i++
            ) {
              if (
                this.businessCardDetails.provided_services[i] ==
                this.privateCleaningID
              ) {
                this.isPrivateCleaning = true;
                this.providedService.push("Private cleaning");
              }
              if (
                this.businessCardDetails.provided_services[i] ==
                this.windowCleaningID
              ) {
                this.isWindowCleaning = true;
                this.providedService.push("Window cleaning");
              }
            }
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error.error.message, "Error");
        }
      );
    }
    this.goToPricePage();
  }
  getBusinessCardDetails() {
    this.spinner.show();
    let userDetails = JSON.parse(localStorage.getItem("sp-user"));
    let user_id = userDetails._id;
    this.companyService.businessCardDetails({ user_id: user_id }).subscribe(
      (response) => {
        localStorage.setItem(
          "businessCardDetails",
          JSON.stringify(response.data)
        );
        this.businessCardDetails = response.data ? response.data : {};
        this.getMetaData();

        this.spinner.hide();
        // this.toastr.success(response["message"], "Success");
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message, "Error");
      }
    );
  }
  goToPricePage() {
    let permission = [];
    if (this.isPrivateCleaning && this.isWindowCleaning) {
      permission = ["Edit_Price", "Both_Window_Private_Cleaning"];
    } else if (this.isPrivateCleaning) {
      permission = ["Edit_Price", "Private_Cleaning"];
    } else if (this.isWindowCleaning) {
      permission = ["Edit_Price", "Window_Cleaning"];
    } else {
      permission = [];
    }
    localStorage.setItem("permission", JSON.stringify(permission));
    this.permissionsService.loadPermissions(permission);
    this.managePermission();
  }
  logout() {
    localStorage.clear();
    localStorage.setItem("selectedLanguage", this.selectedLanguage);
    this.router.navigate(["/login"]);
  }
}
