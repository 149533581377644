<div class="app-body">
  <main class="main d-flex align-items-center vh-100">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <div class="d-flex justify-content-center"></div>
                <form
                  [formGroup]="loginForm"
                  (ngSubmit)="onRegistrationFormSubmit()"
                  novalidate
                >
                  <h1>{{ "register.title" | translate }}</h1>
                  <p class="text-muted">
                    {{ "register.create_your_account" | translate }}
                  </p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="icon-user"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'register.first_name.placeholder' | translate
                      }}"
                      formControlName="firstName"
                      required
                    />
                    <div
                      class="text-danger error d-flex w-100"
                      *ngIf="isSubmitted && loginForm.controls.firstName.errors"
                    >
                      <div *ngIf="loginForm.hasError('required', 'firstName')">
                        {{
                          "register.first_name.validation.required" | translate
                        }}
                      </div>
                      <div
                        *ngIf="
                          !loginForm.hasError('required', 'firstName') &&
                          loginForm.hasError('pattern', 'firstName')
                        "
                      >
                        {{
                          "register.first_name.validation.min_character"
                            | translate
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="icon-user"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'register.last_name.placeholder' | translate
                      }}"
                      formControlName="lastName"
                      required
                    />
                    <div
                      class="text-danger error d-flex w-100"
                      *ngIf="isSubmitted && loginForm.controls.lastName.errors"
                    >
                      <div *ngIf="loginForm.hasError('required', 'lastName')">
                        {{
                          "register.last_name.validation.required" | translate
                        }}
                      </div>
                      <div
                        *ngIf="
                          !loginForm.hasError('required', 'lastName') &&
                          loginForm.hasError('pattern', 'lastName')
                        "
                      >
                        {{
                          "register.last_name.validation.min_character"
                            | translate
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">@</span>
                    </div>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="{{
                        'register.email.placeholder' | translate
                      }}"
                      formControlName="email"
                      required
                    />
                    <div
                      class="text-danger error d-flex w-100"
                      *ngIf="isSubmitted && loginForm.controls.email.errors"
                    >
                      <div *ngIf="loginForm.hasError('required', 'email')">
                        {{ "register.email.validation.required" | translate }}
                      </div>
                      <div
                        *ngIf="
                          !loginForm.hasError('required', 'email') &&
                          loginForm.hasError('pattern', 'email')
                        "
                      >
                        {{ "register.email.validation" | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="icon-phone"></i
                      ></span>
                    </div>
                    <input
                      type="phone"
                      class="form-control"
                      placeholder="{{
                        'register.phone.placeholder' | translate
                      }}"
                      autocomplete="off"
                      formControlName="mobile_number"
                      (keypress)="numberOnly($event)"
                      minlength="8"
                      maxlength="10"
                      required
                    />
                    <div
                      class="text-danger error d-flex w-100"
                      *ngIf="
                        isSubmitted && loginForm.controls.mobile_number.errors
                      "
                    >
                      <div
                        *ngIf="loginForm.hasError('required', 'mobile_number')"
                      >
                        {{ "register.phone.validation.required" | translate }}
                      </div>
                      <div
                        *ngIf="
                          !loginForm.hasError('required', 'mobile_number') &&
                          loginForm.hasError('minlength', 'mobile_number')
                        "
                      >
                        {{ "register.phone.validation" | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="icon-lock"></i
                      ></span>
                    </div>

                    <input
                      type="password"
                      class="form-control"
                      placeholder="{{
                        'register.password.placeholder' | translate
                      }}"
                      formControlName="password"
                      required
                    />
                    <div
                      class="text-danger error d-flex w-100"
                      *ngIf="isSubmitted && loginForm.controls.password.errors"
                    >
                      <div *ngIf="loginForm.hasError('required', 'password')">
                        {{
                          "register.password.validation.required" | translate
                        }}
                      </div>
                      <div
                        *ngIf="
                          !loginForm.hasError('required', 'password') &&
                          (loginForm.hasError('minlength', 'password') ||
                            loginForm.hasError('maxlength', 'password'))
                        "
                      >
                        {{ "register.password.validation" | translate }}
                      </div>
                    </div>
                    
                  </div>
                  <div class="input-group mb-3 ml-4">
                    <input type="checkbox" class="form-check-input" formControlName="tearmsCheckbox" id="cb" required>
                    <label class="form-check-label" for="exampleCheck1">
                      {{'register.read_accept.text' | translate}}
                       <a [href]="tearmsAndConditionURLBasedOnSelectedLanguage" target="_blank">{{'register.terms_conditions.text' | translate}}<sup>*</sup></a></label>
                  </div>
                  <div
                  class="text-danger error d-flex w-100"
                  *ngIf="isSubmitted && loginForm.controls.tearmsCheckbox.errors"
                >
                  <div *ngIf="loginForm.hasError('required', 'tearmsCheckbox')">
                    {{ "register.terms_and_condition.validation.required" | translate }}
                  </div>
                </div>
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <button type="submit" class="btn btn-primary px-4">
                          {{ "register.get_started.button" | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-link px-0"
                          [routerLink]="['/login']"
                        >
                          {{ "register.back_to_login.link" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
