import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
// import { NotificationService } from "../services/notification.service";
@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  cuurentToken = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging, 
    // private notificationService: NotificationService
    ) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        /**
         * Show notification popup for both forground as well as back ground.
         */
        _messaging.onMessage((payload) => {
          // this.notificationService.getNotificationCount()

          navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
            console.log('registration: ', registration);
            registration.showNotification(
              payload.notification.title,
              payload.notification
            )
            this.currentMessage.next(payload);
          });
        });;
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
  * request permission for notification from firebase cloud messaging
  * 
  * @param userId userId
  */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
      },
      (err) => {
        // console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
  * hook method when new notification received in foreground
  */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log('payload: ', payload);
      })
  }
  /**
    * request permission for notification from firebase cloud messaging
    * 
    */
  requestToken() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.cuurentToken.next(token);
      },
      (err) => {
        // console.error('Unable to get permission to notify.', err);
      }
    );
  }

}