/**
 * This is nav bar for english
 */
export const navItemsEnglish = [
    //   {
    //       name: 'Company Management',
    //       url: '/company-management',
    //       icon: 'fa fa-building'
    //   },
    // {
    //     name: "Manager Management",
    //     url: "/manage-management",
    //     icon: "fa fa-user",
    // },
    // {
    //     name: "Employee Management",
    //     url: "/employee-management",
    //     icon: "fa fa-users",
    // },
    {
        name: "Company",
        url: "/edit-company",
        icon: "fa fa-building",
        children: [
            {
                name: 'Edit Company',
                url: '/edit-company/my-company',
                class: 'nav-pl',
                icon: "fa fa-building",
            },
            {
                name: 'Edit Service',
                url: '/edit-company/services',
                class: 'nav-pl',
                icon: "fa fa-cog",
            },
            {
                name: 'Edit Work Area',
                url: '/edit-company/work-areas',
                class: 'nav-pl',
                icon: "fa fa-briefcase",
            },
            // {
            //     name: 'Order Management ',
            //     url: '/edit-company/order-management',
            //     class: 'nav-pl',
            //     icon: "fa fa-shopping-cart",
            // }
        ],
    },
    {
        name: "Rate Card",
        url: "/edit-rating-card",
        permissions: 'Private_Cleaning',
        icon: "fa fa-id-card-o",
        children: [
            {
                name: 'Edit Private Cleaning',
                url: '/edit-rating-card/my-prices-service',
                class: 'nav-pl',
                permissions: 'Private_Cleaning',
                icon: "fa fa-shower"
            }
        ]
    },
    {
        name: "Rate Card",
        url: "/edit-rating-card",
        permissions: 'Window_Cleaning',
        icon: "fa fa-id-card-o",
        children: [
            {
                name: 'Edit Window Cleaning',
                url: '/edit-rating-card/my-prices',
                class: 'nav-pl',
                permissions: 'Window_Cleaning',
                icon: "fa fa-window-maximize"
            }
        ]
    },
    {
        name: "Rate Card",
        url: "/edit-rating-card",
        permissions: 'Both_Window_Private_Cleaning',
        icon: "fa fa-id-card-o",
        children: [
            {
                name: 'Edit Private Cleaning',
                url: '/edit-rating-card/my-prices-service',
                class: 'nav-pl',
                permissions: 'Private_Cleaning',
                icon: "fa fa-shower",
            },
            {
                name: 'Edit Window Cleaning',
                url: '/edit-rating-card/my-prices',
                class: 'nav-pl',
                permissions: 'Window_Cleaning',
                icon: "fa fa-window-maximize",
            }
        ]
    },
    {
        name: "Manager",
        url: "/edit-company",
        icon: "fa fa-user",
        children: [
            {
                name: 'Add/Cancel Manager',
                url: '/edit-company/managers',
                class: 'nav-pl',
                icon: "fa fa-user-plus",
            },
            {
                name: 'Your Managers',
                url: '/manage-management',
                class: 'nav-pl',
                icon: "fa fa-user-circle-o",
            }
        ]
    },
    {
        name: "Employee",
        url: "/edit-company",
        icon: "fa fa-users",
        children: [
            {
                name: 'Add/Cancel Employee',
                url: '/edit-company/employees',
                class: 'nav-pl',
                icon: "fa fa-user-plus",
            },
            {
                name: 'Your Employees',
                url: '/employee-management',
                class: 'nav-pl',
                icon: "fa fa-user-circle-o",
            }
        ]
    },
    {
        name: "Wallet",
        url: "/manage-wallet",
        icon: "fa fa-google-wallet",
        children: [
            // {
            //     name: 'Wallet',
            //     url: '/manage-wallet/manage-wallet',
            //     class: 'nav-pl',
            //     icon: "fa fa-google-wallet",
            // },
            {
                name: 'Wallet History',
                url: '/manage-wallet/wallet-history',
                class: 'nav-pl',
                icon: "fa fa-list-alt",
            },
            {
                name: 'Earning History',
                url: '/manage-wallet/earning-history',
                class: 'nav-pl',
                icon: "fa fa-money"
            },
            {
                name: 'Withdraw Summary',
                url: '/manage-wallet/wallet-summary',
                class: 'nav-pl',
                icon: "fa fa-list"
            }
        ]
    },
    // {
    //     name: "Chat",
    //     url: "/chat",
    //     icon: "fa fa-comment",
    // },
    {
        name: 'Calendar',
        url: '/edit-company/order-management',
        icon: "fa fa-calendar",
    },
    {
        name: 'Chat',
        url: '/chat-view',
        icon: 'fa fa-comment'     
    },
    {
        name: 'Notification',
        url: '/notification',
        icon: 'fa fa-lock',
      },
      {
        name: 'Terms And Condition',
        url: '/terms-and-condition',
        icon: 'fa fa-file',
      },
      {
        name: 'Standard Cleaning',
        url: '/standard-cleaning/list',
        icon: 'fa fa-file',
      },
      {
        name: 'Custom Order',
        url: '/custom-order/order-management',
        icon: 'fa fa-calendar',
      },
];
/**
 * This is nav bar for arabic
 */
export const navItemsArabic = [
    {
        name: "شركة",
        url: "/edit-company",
        icon: "fa fa-building",
        children: [
            {
                name: 'تحرير الشركة',
                url: '/edit-company/my-company',
                class: 'nav-pl',
                icon: "fa fa-building",
            },
            {
                name: 'تحرير الخدمة',
                url: '/edit-company/services',
                class: 'nav-pl',
                icon: "fa fa-cog",
            },
            {
                name: 'تحرير منطقة العمل',
                url: '/edit-company/work-areas',
                class: 'nav-pl',
                icon: "fa fa-briefcase",
            },
            // {
            //     name: 'Order Management ',
            //     url: '/edit-company/order-management',
            //     class: 'nav-pl',
            //     icon: "fa fa-shopping-cart",
            // }
        ],
    },
    {
        name: "سعر البطاقة",
        url: "/edit-rating-card",
        permissions: 'Private_Cleaning',
        icon: "fa fa-id-card-o",
        children: [
            {
                name: 'تحرير التنظيف الخاص',
                url: '/edit-rating-card/my-prices-service',
                class: 'nav-pl',
                permissions: 'Private_Cleaning',
                icon: "fa fa-shower"
            }
        ]
    },
    {
        name: "سعر البطاقة",
        url: "/edit-rating-card",
        permissions: 'Window_Cleaning',
        icon: "fa fa-id-card-o",
        children: [
            {
                name: 'تحرير تنظيف النوافذ',
                url: '/edit-rating-card/my-prices',
                class: 'nav-pl',
                permissions: 'Window_Cleaning',
                icon: "fa fa-window-maximize"
            }
        ]
    },
    {
        name: "سعر البطاقة",
        url: "/edit-rating-card",
        permissions: 'Both_Window_Private_Cleaning',
        icon: "fa fa-id-card-o",
        children: [
            {
                name: 'تحرير التنظيف الخاص',
                url: '/edit-rating-card/my-prices-service',
                class: 'nav-pl',
                permissions: 'Private_Cleaning',
                icon: "fa fa-shower",
            },
            {
                name: 'تحرير تنظيف النوافذ',
                url: '/edit-rating-card/my-prices',
                class: 'nav-pl',
                permissions: 'Window_Cleaning',
                icon: "fa fa-window-maximize",
            }
        ]
    },
    {
        name: "مدير",
        url: "/edit-company",
        icon: "fa fa-user",
        children: [
            {
                name: 'إضافة / إلغاء مدير',
                url: '/edit-company/managers',
                class: 'nav-pl',
                icon: "fa fa-user-plus",
            },
            {
                name: 'مديرك',
                url: '/manage-management',
                class: 'nav-pl',
                icon: "fa fa-user-circle-o",
            }
        ]
    },
    {
        name: "الموظف",
        url: "/edit-company",
        icon: "fa fa-users",
        children: [
            {
                name: 'إضافة / إلغاء موظف',
                url: '/edit-company/employees',
                class: 'nav-pl',
                icon: "fa fa-user-plus",
            },
            {
                name: 'موظفيك',
                url: '/employee-management',
                class: 'nav-pl',
                icon: "fa fa-user-circle-o",
            }
        ]
    },
    {
        name: "محفظة",
        url: "/manage-wallet",
        icon: "fa fa-google-wallet",
        children: [
            // {
            //     name: 'محفظة',
            //     url: '/manage-wallet/manage-wallet',
            //     class: 'nav-pl',
            //     icon: "fa fa-google-wallet",
            // },
            {
                name: 'محفوظات المحفظة',
                url: '/manage-wallet/wallet-history',
                class: 'nav-pl',
                icon: "fa fa-list-alt",
            },
            {
                name: 'تاريخ الكسب',
                url: '/manage-wallet/earning-history',
                class: 'nav-pl',
                icon: "fa fa-money"
            },
            {
                name: 'سحب ملخص',
                url: '/manage-wallet/wallet-summary',
                class: 'nav-pl',
                icon: "fa fa-list"
            }
        ]
    },
    // {
    //     name: "Chat",
    //     url: "/chat",
    //     icon: "fa fa-comment",
    // },
    {
        name: 'التقويم',
        url: '/edit-company/order-management',
        icon: "fa fa-calendar",
    },
    {
        name: 'محادثة',
        url: '/chat-view',
        icon: 'fa fa-comment'     
    },
    {
        name: 'تنبيه',
        url: '/notification',
        icon: 'fa fa-lock',
      },
      {
        name: 'أحكام وشروط',
        url: '/terms-and-condition/terms-and-condition-arabic',
        icon: 'fa fa-file',
      },
      {
        name: 'التنظيف القياسي',
        url: '/standard-cleaning/list',
        icon: 'fa fa-file',
      },
      {
        name: 'طلبية الزبون',
        url: '/custom-order/order-management',
        icon: 'fa fa-calendar',
      },
];
/**
 * This is nav bar for danish
 */
export const navItemsDanish = [
    {
        name: "Firma",
        url: "/edit-company",
        icon: "fa fa-building",
        children: [
            {
                name: 'Redigere Firma',
                url: '/edit-company/my-company',
                class: 'nav-pl',
                icon: "fa fa-building",
            },
            {
                name: 'Redigerer Ydelser',
                url: '/edit-company/services',
                class: 'nav-pl',
                icon: "fa fa-cog",
            },
            {
                name: 'Redigerer Arbejdsområder',
                url: '/edit-company/work-areas',
                class: 'nav-pl',
                icon: "fa fa-briefcase",
            },
            // {
            //     name: 'Order Management ',
            //     url: '/edit-company/order-management',
            //     class: 'nav-pl',
            //     icon: "fa fa-shopping-cart",
            // }
        ],
    },
    {
        name: "Prisliste",
        url: "/edit-rating-card",
        permissions: 'Private_Cleaning',
        icon: "fa fa-id-card-o",
        children: [
            {
                name: 'Redigerer Privat rengøring',
                url: '/edit-rating-card/my-prices-service',
                class: 'nav-pl',
                permissions: 'Private_Cleaning',
                icon: "fa fa-shower"
            }
        ]
    },
    {
        name: "Prisliste",
        url: "/edit-rating-card",
        permissions: 'Window_Cleaning',
        icon: "fa fa-id-card-o",
        children: [
            {
                name: 'Redigerer Vinduespolering',
                url: '/edit-rating-card/my-prices',
                class: 'nav-pl',
                permissions: 'Window_Cleaning',
                icon: "fa fa-window-maximize"
            }
        ]
    },
    {
        name: "Prisliste",
        url: "/edit-rating-card",
        permissions: 'Both_Window_Private_Cleaning',
        icon: "fa fa-id-card-o",
        children: [
            {
                name: 'Redigerer Privat rengøring',
                url: '/edit-rating-card/my-prices-service',
                class: 'nav-pl',
                permissions: 'Private_Cleaning',
                icon: "fa fa-shower",
            },
            {
                name: 'Redigerer Vinduespolering',
                url: '/edit-rating-card/my-prices',
                class: 'nav-pl',
                permissions: 'Window_Cleaning',
                icon: "fa fa-window-maximize",
            }
        ]
    },
    {
        name: "Manager",
        url: "/edit-company",
        icon: "fa fa-user",
        children: [
            {
                name: 'Tilfør/Slette Manager',
                url: '/edit-company/managers',
                class: 'nav-pl',
                icon: "fa fa-user-plus",
            },
            {
                name: 'Dine Managers',
                url: '/manage-management',
                class: 'nav-pl',
                icon: "fa fa-user-circle-o",
            }
        ]
    },
    {
        name: "Ansatte",
        url: "/edit-company",
        icon: "fa fa-users",
        children: [
            {
                name: 'Tilfør/Slette Ansatte',
                url: '/edit-company/employees',
                class: 'nav-pl',
                icon: "fa fa-user-plus",
            },
            {
                name: 'Dine Ansatte',
                url: '/employee-management',
                class: 'nav-pl',
                icon: "fa fa-user-circle-o",
            }
        ]
    },
    {
        name: "Tegnebog",
        url: "/manage-wallet",
        icon: "fa fa-google-wallet",
        children: [
            // {
            //     name: 'Tegnebog',
            //     url: '/manage-wallet/manage-wallet',
            //     class: 'nav-pl',
            //     icon: "fa fa-google-wallet",
            // },
            {
                name: 'Tegnebog Historik',
                url: '/manage-wallet/wallet-history',
                class: 'nav-pl',
                icon: "fa fa-list-alt",
            },
            {
                name: 'Optjenings Historik',
                url: '/manage-wallet/earning-history',
                class: 'nav-pl',
                icon: "fa fa-money"
            },
            {
                name: 'Udbetalt Opsummering',
                url: '/manage-wallet/wallet-summary',
                class: 'nav-pl',
                icon: "fa fa-list"
            }
        ]
    },
    // {
    //     name: "Chat",
    //     url: "/chat",
    //     icon: "fa fa-comment",
    // },
    {
        name: 'Kalender',
        url: '/edit-company/order-management',
        icon: "fa fa-calendar",
    },
    {
        name: 'Chat',
        url: '/chat-view',
        icon: 'fa fa-comment'     
    },
    {
        name: 'Notifikationer',
        url: '/notification',
        icon: 'fa fa-lock',
      },
      {
        name: 'Regler Og Vilkår',
        url: '/terms-and-condition/terms-and-condition-danish',
        icon: 'fa fa-file',
      },
      {
        name: 'Standard rengøring',
        url: '/standard-cleaning/list',
        icon: 'fa fa-file',
      },
      {
        name: 'Brugerdefineret ordre',
        url: '/custom-order/order-management',
        icon: 'fa fa-calendar',
      },
];