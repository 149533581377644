import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AUTH, DEFINE, CLEANING_POSITION } from "../../shared/config/define";
import { ToastrService } from "ngx-toastr";
import { Validations } from "../../shared/helpers/Validations";
import { LoginService } from "../../shared/services/login.service";
import { NgxPermissionsService } from "ngx-permissions";
import { AdminService } from "../../shared/services/admin.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { TranslatorService } from "../../shared/services/translator.service";
import * as io from "socket.io-client";
import { NgxSpinnerService } from "ngx-spinner";
import { MessagingService } from "../../shared/services/messaging.service";
import { CompanyService } from "../../shared/services/company.service";
import { BsModalService } from 'ngx-bootstrap/modal';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(modalService, formBuilder, router, toastr, loginService, 
    // private ngxRolesService : NgxRolesService,
    permissionsService, adminService, translate, spinner, translator, messagingService, companyService, route, translatorService) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.toastr = toastr;
        this.loginService = loginService;
        this.permissionsService = permissionsService;
        this.adminService = adminService;
        this.translate = translate;
        this.spinner = spinner;
        this.translator = translator;
        this.messagingService = messagingService;
        this.companyService = companyService;
        this.route = route;
        this.translatorService = translatorService;
        this.submitted = false;
        this.socketURL = environment.socketChatURL;
        this.isWindowCleaning = false;
        this.isPrivateCleaning = false;
        this.businessCardDetails = {};
        this.config = {
            class: "video-modal"
        };
        var getSelectedLanguage = localStorage.getItem("selectedLanguage");
        if (getSelectedLanguage) {
            this.selectedLanguage = getSelectedLanguage;
            this.translate.use(getSelectedLanguage);
            if (this.selectedLanguage == 'ar') {
                var body = document.getElementsByTagName('body')[0];
                body.classList.add('body-ar');
            }
            else {
                var body = document.getElementsByTagName('body')[0];
                body.classList.remove("body-ar");
            }
        }
        else {
            this.selectedLanguage = environment.default_language;
            this.translate.setDefaultLang(environment.default_language);
        }
        console.log("this.route.snapshot.data['title']", this.route.snapshot.data['title']);
        this.translatorService.traslateTabTitle(this.route.snapshot.data['title'] ? this.route.snapshot.data['title'] : "app_routing_breadcrumb.login");
        // this.translate.use('en');
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if ((params && params.language == "en") ||
                params.language == "da" ||
                params.language == "ar") {
                _this.setLang(params.language);
            }
        });
        if (JSON.parse(localStorage.getItem("is_login")) == false) {
            localStorage.clear();
        }
        if (localStorage.getItem("sp-user")) {
            var userInfo = JSON.parse(localStorage.getItem("sp-user"));
            this.getBusinessCardDetails(userInfo);
        }
        this.messagingService.requestToken();
        this.messagingService.cuurentToken.subscribe(function (token) {
            _this.msgToken = token;
            console.log("this.msgToken : ", _this.msgToken);
        });
        // check if current user detail stored then redirect on dashboard
        var currentUserToken = localStorage.getItem(AUTH.TOKEN_KEY);
        var currentUser = localStorage.getItem(AUTH.USER_DETAIL);
        // if (currentUser && currentUserToken) {
        //   this.router.navigate(['/company/add']);
        // }
        this.createForm();
    };
    LoginComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template, this.config);
    };
    LoginComponent.prototype.getBusinessCardDetails = function (companyUserDetails) {
        var _this = this;
        this.spinner.show();
        this.companyService.businessCardDetails({ user_id: companyUserDetails._id }).subscribe(function (response) {
            localStorage.setItem("businessCardDetails", JSON.stringify(response.data));
            _this.businessCardDetails = response.data ? response.data : {};
            _this.getMetaData(companyUserDetails);
            _this.spinner.hide();
            // this.toastr.success(response["message"], "Success");
        }, function (error) {
            _this.spinner.hide();
            _this.toastr.error(error.error.message, "Error");
        });
    };
    LoginComponent.prototype.getLangs = function () {
        return this.translator.getAvailableLanguages();
    };
    LoginComponent.prototype.setLang = function (value) {
        //this.translator.useLanguage(value);
        this.translate.use(value);
        this.selectedLanguage = value;
        localStorage.setItem("selectedLanguage", this.selectedLanguage);
        console.log('selectedValue', value);
        var body = document.getElementsByTagName('body')[0];
        if (this.selectedLanguage == 'ar') {
            body.classList.add('body-ar');
        }
        else {
            body.classList.remove("body-ar");
        }
        console.log("this.route.snapshot.data['title']", this.route.snapshot.data['title']);
        this.translatorService.traslateTabTitle(this.route.snapshot.data['title']);
    };
    /**
     * Create login reactive from for get value from user and validate form
     */
    LoginComponent.prototype.createForm = function () {
        this.loginForm = this.formBuilder.group({
            email: [
                "",
                [Validators.required, Validators.pattern(Validations.email_pattern)],
            ],
            password: [
                "",
                [
                    Validators.required,
                    Validators.minLength(6),
                    Validators.maxLength(20),
                    Validators.pattern(Validations.whitespace),
                ],
            ],
        });
    };
    Object.defineProperty(LoginComponent.prototype, "getControls", {
        /**
         * Getter method for get all controls of login form
         */
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * On form submit call login api and on success redirect on home page
     */
    LoginComponent.prototype.loginSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.loginForm.valid) {
            this.spinner.show();
            this.loginService
                .adminLogin(tslib_1.__assign({}, this.loginForm.value, { device_id: "web", access_role: "partner", login_as_provider: false, device_type: 3, fcm_token: this.msgToken != null ? this.msgToken : "fcmtoken" }))
                .subscribe(function (res) {
                localStorage.clear();
                _this.toastr.success("Login successfully!", "Success");
                var adminDetails = res.data ? res.data : null;
                if (adminDetails) {
                    localStorage.setItem(AUTH.TOKEN_KEY, adminDetails.device_details[0].access_token);
                    localStorage.setItem(AUTH.USER_DETAIL, JSON.stringify(adminDetails));
                    localStorage.setItem("is_login", JSON.stringify(false));
                    localStorage.setItem("selectedLanguage", _this.selectedLanguage);
                }
                console.log("admin", adminDetails);
                if (!adminDetails.company_signup_steps.company_details) {
                    _this.router.navigate(["/company/my-company"]);
                }
                else if (!adminDetails.company_signup_steps.work_area) {
                    _this.router.navigate(["/company/work-areas"]);
                }
                else if (adminDetails.company_signup_steps.is_signup_complete) {
                    localStorage.setItem(AUTH.TOKEN_KEY, adminDetails.device_details[0].access_token);
                    localStorage.setItem(AUTH.USER_DETAIL, JSON.stringify(adminDetails));
                    localStorage.setItem("is_login", JSON.stringify(true));
                    localStorage.setItem("selectedLanguage", _this.selectedLanguage);
                    // // set permission list
                    // const perm =
                    //   adminDetails.role_details &&
                    //   adminDetails.role_details.permission
                    //     ? adminDetails.role_details.permission
                    //     : [];
                    // this.permissionsService.loadPermissions(perm);
                    _this.user_connection(adminDetails._id);
                    // this.spinner.hide();
                    _this.getBusinessCardDetails(adminDetails);
                    console.log(_this.isPrivateCleaning, _this.isWindowCleaning);
                }
                else {
                    var finalErrMsg = DEFINE.common_error_msg;
                    _this.toastr.error(finalErrMsg, "Error");
                    _this.spinner.hide();
                }
            }, function (error) {
                var errMsg = {
                    en: 'You cannot login till admin has verified.',
                    da: 'Du kan ikke logge ind, før admin er bekræftet.',
                    ar: 'لا يمكنك تسجيل الدخول حتى يتحقق المسؤول.'
                };
                var finalErrMsg = error && error.error.message
                    ? (error.error.message === "ADMIN_YET_TO_VERIFY" ? errMsg[_this.selectedLanguage] : DEFINE.common_error_msg)
                    : DEFINE.common_error_msg;
                _this.toastr.error(finalErrMsg, "Error");
                _this.spinner.hide();
            });
        }
    };
    /**
     * If no manager is added in that company then redirect to manager add page. if no rating card added for that company then redirect to rating page based on selected service i.e private cleaning and window cleaning. else redirect to manager list page
     */
    LoginComponent.prototype.goToPageBasedOnCondition = function (adminDetails) {
        if (!adminDetails || !adminDetails.company || !adminDetails.company || !adminDetails.company.company_signup_process_flag || !adminDetails.company.company_signup_process_flag.isRatingCardExist) {
            // if (this.isPrivateCleaning) {
            this.router.navigate(["/how_it_works/video"]);
            // } else {
            //   this.router.navigate(["/my-prices/my-prices"]);
            // }
        }
        else if (!adminDetails || !adminDetails.company || !adminDetails.company || !adminDetails.company.company_signup_process_flag || !adminDetails.company.company_signup_process_flag.isManagerExist) {
            this.router.navigate(["/company/managers"]);
        }
        else {
            this.router.navigate(["/manage-management"]);
        }
    };
    /**
     * This function is used when user connect.
     */
    LoginComponent.prototype.user_connection = function (id) {
        var _this = this;
        this.socket = io(environment.socketChatURL);
        this.socket.on("connect", function () {
            _this.socket.emit("user_join", { user_id: id, socket_id: _this.socket.id }, function (data) {
                console.log("RESPONSE CONNECTION:->", data);
                if (data.status == 200) {
                    _this.spinner.hide();
                    // this.router.navigate(["/manage-management"]);
                }
            });
        });
    };
    LoginComponent.prototype.getMetaData = function (companyUserDetails) {
        var _this = this;
        this.adminService.getMetaData({}).subscribe(function (response) {
            var metaData = {
                windowCleaningID: response.data.services[CLEANING_POSITION[1].index].main._id,
                privateCleaningID: response.data.services[CLEANING_POSITION[0].index].main._id,
                otherDetails: response.data.services,
            };
            localStorage.setItem("metaData", JSON.stringify(metaData));
            console.log("this.businessCardDetails", _this.businessCardDetails.provided_services);
            if (_this.businessCardDetails &&
                _this.businessCardDetails.provided_services &&
                _this.businessCardDetails.provided_services.length > 0) {
                for (var i = 0; i < _this.businessCardDetails.provided_services.length; i++) {
                    ;
                    if (_this.businessCardDetails.provided_services[i] ==
                        metaData.privateCleaningID) {
                        _this.isPrivateCleaning = true;
                    }
                    if (_this.businessCardDetails.provided_services[i] ==
                        metaData.windowCleaningID) {
                        _this.isWindowCleaning = true;
                    }
                }
            }
            _this.goToPageBasedOnCondition(companyUserDetails);
        }, function (error) { });
    };
    return LoginComponent;
}());
export { LoginComponent };
