<div class="mt-1 mr-2 ml-auto">	
	<select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
		<option [value]="lang.code" *ngFor="let lang of getLangs()">{{lang.text}}</option>
	</select>
</div>
<!-- <div class="mt-1 mr-2 ml-auto">	
	<select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
		<option [value]="lang.code" *ngFor="let lang of getLangs()">{{lang.text}}</option>
	</select>
</div> -->
<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body">
                                <form [formGroup]="forgotPassForm" (ngSubmit)="forgotPassFormSubmit()">
                                    <h1>{{'forgotPassword.title' | translate }}</h1>
                                    <p class="text-muted">{{'forgotPassword.recover_your_password' | translate }}</p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="icon-user"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" formControlName="email"
                                            placeholder="{{'forgotPassword.email.placeholder' | translate}}"
                                            autocomplete="off" trim="blur">
                                        <br />
                                        <div class="error"
                                            *ngIf="(submitted && getControls.email.invalid) || getControls.email.touched">
                                            <div *ngIf="getControls.email.errors?.required;else checkPattern"
                                                class="err-msg">
                                                {{'forgotPassword.email.validation.required' | translate }}</div>
                                            <ng-template #checkPattern>
                                                <div *ngIf="getControls.email.errors?.pattern" class="err-msg">
                                                    {{'forgotPassword.email.validation' | translate }}</div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <button type="submit"
                                                class="btn btn-primary px-4">{{'forgotPassword.submit.button' |
                                                translate
                                                }}</button>
                                        </div>
                                        <div class="col-6 text-right forgot-password-text">
                                            <button type="button" class="btn btn-link px-0"
                                                [routerLink]="['/login']">{{'forgotPassword.login.button' | translate
                                                }}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>