import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { environment } from '../../../environments/environment';

export const DEFINE = {
    admin_email: 'admin@easyr.dk',
    app_title: 'EasyR',
    app_url: 'www.helpinghands.org',
    powered_by_title: 'EasyR Private LTD.',
    powered_by_url: 'www.helpinghands.org',
    common_error_msg: 'Oops! something went wrong!',
    anthorized_msg: 'You are not authorized to access this page!',
    per_page: environment.per_page,
    swalGreen: '#E5A630',
    swalYellow: '#008000',
    display_created_date_format: 'dd-MMMM-yyyy hh:mm a',
    display__date_format: 'dd-MMMM-yyyy',
    detail_not_found_timeout: 1000,
    max_length_show: 100,
    currency_symbol: 'DKK',
    default_language_code: 'en',
    no_data_found: 'No data found!',
    no_filter_data_found: 'No data found!',
    min_tax_value: 0
}

export const AUTH = {
    TOKEN_KEY: 'company_admin_token',
    USER_DETAIL: 'sp-user'
}

export const APP_USER_TYPES = [
    {
        type: 1,
        value: 'Requester'
    },
    {
        type: 2,
        value: 'Individual Provider'
    },
    {
        type: 3,
        value: 'Company Manager'
    },
    {
        type: 4,
        value: 'Employee'
    }
];

export const MAIN_USER_TYPES = [
    {
        type: 1,
        value: 'Requester'
    },
    {
        type: 2,
        value: 'Individual Provider'
    },
    {
        type: 3,
        value: 'Company Manager'
    }
];
export const CLEANING_POSITION = [
    {
        index: 0,
        value: 'private cleaning'
    },
    {
        index: 1,
        value: 'Window cleaning'
    }
];
export const ORDER_STATUS_LIST_EN = [
    {
        status: '0',
        value: 'In Process'
    },
    {
        status: '1',
        value: 'Requested'
    },
    {
        status: '2',
        value: 'Rejected'
    },
    {
        status: '3',
        value: 'Assigned'
    },
    {
        status: '4',
        value: 'Confirmed'
    },
    {
        status: '5',
        value: 'Checked In'
    },
    {
        status: '6',
        value: 'CheckIn Accepted'
    },
    {
        status: '7',
        value: 'CheckIn Not Confirmed'
    },
    {
        status: '8',
        value: 'On Going'
    },
    {
        status: '9',
        value: 'Finished'
    },
    {
        status: '10',
        value: 'Finish Confirmed'
    },
    // {
    //     status: '11',
    //     value: 'Paid'
    // },
    // {
    //     status: '15',
    //     value: 'Requested Again'
    // },
    {
        status: '16',
        value: 'Rejected Again'
    },
    // {
    //     status: '17',
    //     value: 'Reassigned'
    // },
    {
        status: '18',
        value: 'Not Confirmed'
    },
    // {
    //     status : '21',
    //     value : 'Pending Claim'
    // },
    // {
    //     status : '22',
    //     value : 'Claim Acknowledged'
    // },
    // {
    //     status : '23',
    //     value : 'Claim Resolved'
    // },
    {
        status: '31',
        value: 'Pre Canceled'
    },
    {
        status: '32',
        value: 'Cancelled By Requester'
    },
    {
        status: '33',
        value: 'Cancelled By Provider'
    }
]
export const ORDER_STATUS_LIST_DA = [
    {
        status: '0',
        value: 'Under behandling'
    },
    {
        status: '1',
        value: 'Anmodet'
    },
    {
        status: '2',
        value: 'Afvist'
    },
    {
        status: '3',
        value: 'Tildelt'
    },
    {
        status: '4',
        value: 'Bekræftet'
    },
    {
        status: '5',
        value: 'Tjekket ind'
    },
    {
        status: '6',
        value: 'Afkrydsningsfelt accepteret'
    },
    {
        status: '7',
        value: 'Afkrydsningsfeltet ikke bekræftet'
    },
    {
        status: '8',
        value: 'Igangværende'
    },
    {
        status: '9',
        value: 'Færdig'
    },
    {
        status: '10',
        value: 'Afslut Bekræftet'
    },
    // {
    //     status: '11',
    //     value: 'Paid'
    // },
    // {
    //     status: '15',
    //     value: 'Requested Again'
    // },
    {
        status: '16',
        value: 'Afvist igen'
    },
    // {
    //     status: '17',
    //     value: 'Reassigned'
    // },
    {
        status: '18',
        value: 'Ikke bekræftet'
    },
    // {
    //     status : '21',
    //     value : 'Pending Claim'
    // },
    // {
    //     status : '22',
    //     value : 'Claim Acknowledged'
    // },
    // {
    //     status : '23',
    //     value : 'Claim Resolved'
    // },
    {
        status: '31',
        value: 'Forud annulleret'
    },
    {
        status: '32',
        value: 'Annulleret af anmoderen'
    },
    {
        status: '33',
        value: 'Annulleret af udbyder'
    }
]
export const ORDER_STATUS_LIST_AR = [
    {
        status: '0',
        value: 'تحت المعالجة'
    },
    {
        status: '1',
        value: 'مطلوب'
    },
    {
        status: '2',
        value: 'مرفوض'
    },
    {
        status: '3',
        value: 'مكلف'
    },
    {
        status: '4',
        value: 'مؤكد'
    },
    {
        status: '5',
        value: 'تم تسجيل الوصول'
    },
    {
        status: '6',
        value: 'تم قبول تسجيل الوصول'
    },
    {
        status: '7',
        value: 'لم يتم تأكيد تسجيل الوصول'
    },
    {
        status: '8',
        value: 'جاري التنفيذ'
    },
    {
        status: '9',
        value: 'تم الانتهاء من'
    },
    {
        status: '10',
        value: 'تم تأكيد الانتهاء'
    },
    // {
    //     status: '11',
    //     value: 'Paid'
    // },
    // {
    //     status: '15',
    //     value: 'Requested Again'
    // },
    {
        status: '16',
        value: 'رفض مرة أخرى'
    },
    // {
    //     status: '17',
    //     value: 'Reassigned'
    // },
    {
        status: '18',
        value: 'غير مؤكد'
    },
    // {
    //     status : '21',
    //     value : 'Pending Claim'
    // },
    // {
    //     status : '22',
    //     value : 'Claim Acknowledged'
    // },
    // {
    //     status : '23',
    //     value : 'Claim Resolved'
    // },
    {
        status: '31',
        value: 'مُلغى مسبقًا'
    },
    {
        status: '32',
        value: 'ألغاها مقدم الطلب'
    },
    {
        status: '33',
        value: 'تم الإلغاء من قبل المزود'
    }
]
// export const allow_video_format = ['mp4', "avi", "wmv", "flv", "mov", "mkv", 'webm'];
export const allow_video_format = ['mp4', 'mov', 'avi', 'MOV'];
export const allow_image_format = ["png", "jpg", "jpeg"];
export const invalid_image_message_end = " as png, jpg or jpeg.";
export const SERIVE_MAPPING_IDS = {
    deep_cleaning: 11,
    general_cleaning: 12,
    party_cleaning: 13,
    window_cleaning: 21
}

export const datatables_state_list = {
    provider_request: {
        route: 'provider-request',
        value: 'spm_datatables_provider_request'
    },
    languages: {
        route: 'languages',
        value: 'spm_datatables_languages'
    },
    service: {
        route: 'service',
        value: 'spm_datatables_service'
    },
    sub_service: {
        route: 'service/manage_sub',
        value: 'spm_datatables_sub_service'
    },
    service_faq: {
        route: 'faq',
        value: 'spm_datatables_service_faq'
    },
    service_add_on: {
        route: 'add-ons',
        value: 'spm_datatables_service_add_ons'
    },
    service_how_works_it: {
        route: 'how-it-works',
        value: 'spm_datatables_service_how_works'
    },
    service_check_it: {
        route: 'check-list',
        value: 'spm_datatables_service_check_list'
    },
    service_window: {
        route: 'windows',
        value: 'spm_datatables_service_window'
    },
    questionnaire: {
        route: 'questionnaire',
        value: 'spm_datatables_questionnaire'
    },
    questions: {
        route: 'questionnaire/questions',
        value: 'spm_datatables_questions'
    },
    users: {
        route: 'users',
        value: 'spm_datatables_users'
    },
    employee: {
        route: 'users/employee_list',
        value: 'spm_datatables_employee'
    },
    manager: {
        route: 'users/manager_list',
        value: 'spm_datatables_manager'
    },
    users_withdraw_history: {
        route: 'users/withdraw_history',
        value: 'spm_datatables_users_withdraw_history'
    },
    users_earn_history: {
        route: 'users/earning_history',
        value: 'spm_datatables_users_earn_history'
    },
    role: {
        route: 'role',
        value: 'spm_datatables_role'
    },
    room_type: {
        route: 'room-type',
        value: 'spm_datatables_room_type'
    },
    zipcode_group: {
        route: 'zipcode-group',
        value: 'spm_datatables_zipcode_group'
    },
    payment: {
        route: 'payment',
        value: 'spm_datatables_payment'
    },
    admin_manage: {
        route: 'admin-manage',
        value: 'spm_datatables_admin_manage'
    },
    order_manage: {
        route: 'order',
        value: 'spm_datatables_order_manage'
    },
    cms_manage: {
        route: 'cms-manage',
        value: 'spm_datatables_cms_manage'
    },
    notification_manage: {
        route: 'notification',
        value: 'spm_datatables_notification_manage'
    },
    coupon_manage: {
        route: 'coupons',
        value: 'spm_datatables_coupons_manage'
    },
    claim_manage: {
        route: 'claims',
        value: 'spm_datatables_claim_manage_manage'
    },
}

export const NOTIFICATION_USER_TYPES = [
    {
        type: 4,
        value: 'Employee'
    },
    {
        type: 6,
        value: 'Manager'
    }
];
export const NOTIFICATION_USER_TYPES_DA = [
    {
        type: 4,
        value: 'Ansat'
    },
    {
        type: 6,
        value: 'Manager'
    }
];
export const NOTIFICATION_USER_TYPES_AR = [
    {
        type: 4,
        value: 'الموظف'
    },
    {
        type: 6,
        value: 'مدير'
    }
];
export const NOTIFICATION_USER_TYPES_BY_VALUE = {
    0: 'All',
    1: 'Requester',
    2: 'Individual Provider',
    3: 'Company Manager',
    4: 'Selected Users',
    5: 'Window Cleaning Providers',
    6: 'General Cleaning Providers'
};

export const DANISH_DATATABLE_TEXT = {
    processing: "Forarbejdning...",
    search: "Søg:",
    lengthMenu: "Vis _MENU_ Poster",
    info: "Viser _START_ til _END_ af _TOTAL_ poster",
    infoEmpty: "Ingen optegnelser tilgængelige.",
    infoFiltered: "(filtreret fra _MAX_ total records)",
    infoPostFix: "",
    loadingRecords: "Indlæser poster ...",
    zeroRecords: "Ingen registreringer fundet",
    emptyTable: "Ingen data tilgængelige i tabellen"
};

export const ARABIC_DATATABLE_TEXT = {
    processing: "معالجة...",
    search: "بحث:",
    lengthMenu: "تبين _MENU_ إدخالات",
    info: "عرض _START_ ل _END_ من _TOTAL_ إدخالات",
    infoEmpty: "لا توجد سجلات متاحة.",
    infoFiltered: "(تمت تصفيته من _MAX_ إجمالي السجلات)",
    infoPostFix: "",
    loadingRecords: "تحميل السجلات ...",
    zeroRecords: "لا توجد سجلات",
    emptyTable: "لا توجد بيانات متوفرة في الجدول"
};

export const ENGLISH_DATATABLE_TEXT = {
    processing: "Processing...",
    search: "Search:",
    lengthMenu: "Show _MENU_ entries",
    info: "Showing _START_ to _END_ of _TOTAL_ entries",
    infoEmpty: "No records available.",
    infoFiltered: "(filtered from _MAX_ total records)",
    infoPostFix: "",
    loadingRecords: "Loading records ...",
    zeroRecords: "No records found",
    emptyTable: "No data available in table"
};