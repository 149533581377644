/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./403.component";
var styles_P403Component = [];
var RenderType_P403Component = i0.ɵcrt({ encapsulation: 2, styles: styles_P403Component, data: {} });
export { RenderType_P403Component as RenderType_P403Component };
export function View_P403Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "app flex-row align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 14, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 13, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h1", [["class", "float-left display-3 mr-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["403"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h4", [["class", "pt-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Oops! Access Denied."])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["You are not authorized to access this page."])), (_l()(), i0.ɵeld(11, 0, null, null, 5, "div", [["class", "input-prepend input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 4, "span", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["class", "btn btn-info"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(15, 1), (_l()(), i0.ɵted(-1, null, ["Go to home"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 15, 0, "/manage-management"); _ck(_v, 14, 0, currVal_0); }, null); }
export function View_P403Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_P403Component_0, RenderType_P403Component)), i0.ɵdid(1, 49152, null, 0, i2.P403Component, [], null, null)], null, null); }
var P403ComponentNgFactory = i0.ɵccf("ng-component", i2.P403Component, View_P403Component_Host_0, {}, {}, []);
export { P403ComponentNgFactory as P403ComponentNgFactory };
