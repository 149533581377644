import { FormGroup, ValidatorFn } from '@angular/forms';

export const Validations =
    {
        email_pattern: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/,
        phone_pattern: /^[0-9-+() ]*$/,   //THIS STRING DOEST NOT ALLOW THE USER TO ENTER ANY ALPHABETICAL VALUES......
        alpha_numeric: /^[A-Z0-9]+$/i,
        only_alphabetical: /^[A-Za-z]+$/i,
        alpha_numeric1: /^[\w -]*$/,
        url_pattern: /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
        onlyNum_pattern: /^-?(0|[1-9]\d*)?$/,
        price_pattern: /^[0-9]+(\.[0-9]{1,50})?$/,
        decimal: /^[0-9]+(\.[0-9]{1,2})?$/,
        name_pattern: /^.{2,}$/,
        username_pattern: /^[\w.'-]+$/,
        // whitespace: /^\S.*$/,
        whitespace: /^\S*$/,
        mobile_number_pattern: /^-?([1-9]+\d*)?$/,
        number_pattern: /^-?([1-9]\d*)?$/,
        password_pattern: /(?=^[!@#$%\^&*()_\-+=\[{\]};:<>|\./?a-zA-Z\d]{6,}$)(?=([!@#$%\^&*()_\-+=\[{\]};:<>|\./?a-zA-Z\d]*\W+){1,})[!@#$%\^&*()_\-+=\[{\]};:<>|\./?a-zA-Z\d]*$/
    }
    export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
        return function validate (formGroup: FormGroup) {
          let checked = 0;
      
          Object.keys(formGroup.controls).forEach(key => {
            const control = formGroup.controls[key];
      
            if (control.value === true) {
              checked ++;
            }
          });
      
          if (checked < minRequired) {
            return {
              requireCheckboxesToBeChecked: true,
            };
          }
      
          return null;
        };
      }
      /**
 * Password and confirm password must be same validation.
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}