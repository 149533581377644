import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this.isLoading = new BehaviorSubject(false);
        this.currentMessage = this.isLoading.asObservable();
    }
    LoaderService.prototype.changeValue = function (val) {
        this.isLoading.next(val);
    };
    LoaderService.prototype.getValue = function () {
        return this.isLoading;
    };
    LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
