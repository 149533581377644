export const environment = {
  socketChatURL: "https://api.easyr.ae/v7/spdk_chat",
  MEDIA_SEND_URL_FOR_SOCKET:
    "https://api.easyr.ae/v7/api/chatService/uploadChatMedia",
  production: false,
  apiUrl : 'https://api.easyr.ae/v7/',
  per_page: 10,
  common_placeholder: "./assets/img/placeholder/common-placeholder.jpg",
  user_placeholder: "./assets/img/avatars/default.png",
  default_language: "en",
  defaultPropertyImgUrl: "./assets/defualtimg.png",
  //firebase: {
  //   apiKey: "AIzaSyDuumr4qEH1Knoxa9EbPblwHMWLP493XBA",
  //   authDomain: "boxiiq-7ccd1.firebaseapp.com",
  //   databaseURL: "https://boxiiq-7ccd1.firebaseio.com",
  //   projectId: "boxiiq-7ccd1",
  //   storageBucket: "boxiiq-7ccd1.appspot.com",
  //   messagingSenderId: "380832796813",
  //   appId: "1:380832796813:web:16c2f9e0848ddbcec0d2c4",
  // },
  /**
   * Old
   */
  firebase: {
    apiKey: "AIzaSyAI_4oXNWKarqv71TlSRDyK0CZw4piufls",
    authDomain: "serviceprovidermike.firebaseapp.com",
    databaseURL: "https://serviceprovidermike.firebaseio.com",
    projectId: "serviceprovidermike",
    storageBucket: "serviceprovidermike.appspot.com",
    messagingSenderId: "89936959991",
    appId: "1:89936959991:web:1d2a075fd08193e0ad36f2",
    measurementId: "G-WPMLCLZFCR"
  }
};
