import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Validations } from '../../shared/helpers/Validations';
import { DEFINE } from '../../shared/config/define';
import { AdminService } from '../../shared/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { TranslatorService } from "../../shared/services/translator.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPassForm: FormGroup;
  submitted: boolean = false;
  selectedLanguage: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private authService: AdminService,
    private translate:TranslateService,
    public translator: TranslatorService,
    private route:ActivatedRoute,
    private translatorService:TranslatorService
  ) {
    let getSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (getSelectedLanguage) {
      this.selectedLanguage = getSelectedLanguage
      this.translate.use(getSelectedLanguage);
      if(this.selectedLanguage == 'ar'){
        let body = document.getElementsByTagName('body')[0];    
        body.classList.add('body-ar');
      }
      else{
        let body = document.getElementsByTagName('body')[0];    
        body.classList.remove("body-ar");
      }
    } else {
      this.selectedLanguage = environment.default_language
      this.translate.setDefaultLang(environment.default_language);
    }
    this.translatorService.traslateTabTitle(this.route.snapshot.data['title'])
   }

  ngOnInit() {
    this.createForm();
  }
  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    //this.translator.useLanguage(value);
    this.translate.use(value);
    this.selectedLanguage = value;
    localStorage.setItem("selectedLanguage", this.selectedLanguage);
    let body = document.getElementsByTagName('body')[0];    
    body.classList.remove("body-ar");
    if(this.selectedLanguage == 'ar'){
      body.classList.add('body-ar');
    }
    else{
      body.classList.remove("body-ar");
    }
    this.translatorService.traslateTabTitle(this.route.snapshot.data['title'])
  }
  /**
   * Create forgot password reactive from for get email from user and validate form
   */
  createForm() {
    this.forgotPassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(Validations.email_pattern)]]
    });
  }
  /**
   * Getter method for get all controls of forgot password form
   */
  get getControls() { return this.forgotPassForm.controls; }



  /**
   * On form submit call forgot password api and on success redirect on login page
   */
  forgotPassFormSubmit() {
    this.submitted = true;
    if (this.forgotPassForm.valid) {
      this.authService.ForgotPassword(this.forgotPassForm.value).subscribe(res => {
        this.toastr.success(res.message ? res.message : 'Reset link send successfully, please check your mail.', 'Success');
        this.router.navigate(['/login']);

      }, error => {
        this.toastr.error(error.error.message, "Error");

      });
    }
  }

}
