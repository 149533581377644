import { Component, OnInit,TemplateRef  } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AUTH, DEFINE, CLEANING_POSITION } from "../../shared/config/define";
import { ToastrService } from "ngx-toastr";
import { Validations } from "../../shared/helpers/Validations";
import { LoginService } from "../../shared/services/login.service";
import { NgxPermissionsService } from "ngx-permissions";
import { AdminService } from "../../shared/services/admin.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { TranslatorService } from "../../shared/services/translator.service";
import * as io from "socket.io-client";
import { NgxSpinnerService } from "ngx-spinner";
import { MessagingService } from "../../shared/services/messaging.service";
import { CompanyService } from "../../shared/services/company.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html",
})
export class LoginComponent implements OnInit {

  modalRef?: BsModalRef;
  loginForm: FormGroup;
  submitted: boolean = false;
  selectedLanguage: string;
  private socket;
  socketURL: String = environment.socketChatURL;
  userID: any;
  msgToken;
  isWindowCleaning: Boolean = false;
  isPrivateCleaning: Boolean = false;
  businessCardDetails: any = {};
  config = {
    class: "video-modal"
  };
  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private loginService: LoginService,
    // private ngxRolesService : NgxRolesService,
    private permissionsService: NgxPermissionsService,
    private adminService: AdminService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    public translator: TranslatorService,
    private messagingService: MessagingService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private translatorService: TranslatorService
  ) {
    let getSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (getSelectedLanguage) {
      this.selectedLanguage = getSelectedLanguage;
      this.translate.use(getSelectedLanguage);
      if (this.selectedLanguage == 'ar') {
        let body = document.getElementsByTagName('body')[0];
        body.classList.add('body-ar');
      }
      else {
        let body = document.getElementsByTagName('body')[0];
        body.classList.remove("body-ar");
      }
    } else {
      this.selectedLanguage = environment.default_language;
      this.translate.setDefaultLang(environment.default_language);
    }
    console.log("this.route.snapshot.data['title']", this.route.snapshot.data['title'])
    this.translatorService.traslateTabTitle(this.route.snapshot.data['title'] ? this.route.snapshot.data['title'] : "app_routing_breadcrumb.login")
    // this.translate.use('en');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (
        (params && params.language == "en") ||
        params.language == "da" ||
        params.language == "ar"
      ) {
        this.setLang(params.language);
      }
    });
    if (JSON.parse(localStorage.getItem("is_login")) == false) {
      localStorage.clear()
    }
    if (localStorage.getItem("sp-user")) {
      let userInfo = JSON.parse(localStorage.getItem("sp-user"));
      this.getBusinessCardDetails(userInfo);
    }
    this.messagingService.requestToken();
    this.messagingService.cuurentToken.subscribe((token) => {
      this.msgToken = token;
      console.log("this.msgToken : ", this.msgToken);
    });
    // check if current user detail stored then redirect on dashboard
    const currentUserToken = localStorage.getItem(AUTH.TOKEN_KEY);
    const currentUser = localStorage.getItem(AUTH.USER_DETAIL);
    // if (currentUser && currentUserToken) {
    //   this.router.navigate(['/company/add']);
    // }
    this.createForm();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,this.config);
  }
  getBusinessCardDetails(companyUserDetails) {
    this.spinner.show();
    this.companyService.businessCardDetails({ user_id: companyUserDetails._id }).subscribe(
      (response) => {
        localStorage.setItem(
          "businessCardDetails",
          JSON.stringify(response.data)
        );
        this.businessCardDetails = response.data ? response.data : {};
        this.getMetaData(companyUserDetails);

        this.spinner.hide();
        // this.toastr.success(response["message"], "Success");
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message, "Error");
      }
    );
  }
  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    //this.translator.useLanguage(value);
    this.translate.use(value);
    this.selectedLanguage = value;
    localStorage.setItem("selectedLanguage", this.selectedLanguage);
    console.log('selectedValue', value);
    let body = document.getElementsByTagName('body')[0];
    if (this.selectedLanguage == 'ar') {
      body.classList.add('body-ar');
    }
    else {
      body.classList.remove("body-ar");
    }
    console.log("this.route.snapshot.data['title']", this.route.snapshot.data['title'])
    this.translatorService.traslateTabTitle(this.route.snapshot.data['title'])
  }

  /**
   * Create login reactive from for get value from user and validate form
   */
  createForm() {
    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [Validators.required, Validators.pattern(Validations.email_pattern)],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          Validators.pattern(Validations.whitespace),
        ],
      ],
    });
  }
  /**
   * Getter method for get all controls of login form
   */
  get getControls() {
    return this.loginForm.controls;
  }

  /**
   * On form submit call login api and on success redirect on home page
   */
  loginSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.spinner.show();
      this.loginService
        .adminLogin({
          ...this.loginForm.value,
          device_id: "web",
          access_role: "partner",
          login_as_provider: false,
          device_type: 3,
          fcm_token: this.msgToken != null ? this.msgToken : "fcmtoken"
        })
        .subscribe(
          (res) => {
            localStorage.clear();
            this.toastr.success("Login successfully!", "Success");
            const adminDetails = res.data ? res.data : null;
            if (adminDetails) {

              localStorage.setItem(
                AUTH.TOKEN_KEY,
                adminDetails.device_details[0].access_token
              );
              localStorage.setItem(
                AUTH.USER_DETAIL,
                JSON.stringify(adminDetails)
              );
              localStorage.setItem("is_login", JSON.stringify(false));
              localStorage.setItem("selectedLanguage", this.selectedLanguage);

            }
             console.log("admin",adminDetails)

            if (!adminDetails.company_signup_steps.company_details) {
               this.router.navigate(["/company/my-company"]);
            } else if(!adminDetails.company_signup_steps.work_area){
              this.router.navigate(["/company/work-areas"])
            } 
            else if (adminDetails.company_signup_steps.is_signup_complete) {
              localStorage.setItem(
                AUTH.TOKEN_KEY,
                adminDetails.device_details[0].access_token
              );
              localStorage.setItem(
                AUTH.USER_DETAIL,
                JSON.stringify(adminDetails)
              );
              localStorage.setItem("is_login", JSON.stringify(true));
              localStorage.setItem("selectedLanguage", this.selectedLanguage);

              // // set permission list
              // const perm =
              //   adminDetails.role_details &&
              //   adminDetails.role_details.permission
              //     ? adminDetails.role_details.permission
              //     : [];
              // this.permissionsService.loadPermissions(perm);
              this.user_connection(adminDetails._id);
              // this.spinner.hide();
              this.getBusinessCardDetails(adminDetails);
              console.log(this.isPrivateCleaning, this.isWindowCleaning);
            } else {
              const finalErrMsg = DEFINE.common_error_msg;
              this.toastr.error(finalErrMsg, "Error");
              this.spinner.hide();
            }
          },
          (error) => {
            let errMsg ={
              en :'You cannot login till admin has verified.',
              da:'Du kan ikke logge ind, før admin er bekræftet.',
              ar:'لا يمكنك تسجيل الدخول حتى يتحقق المسؤول.'
            }
            const finalErrMsg =
              error && error.error.message
                ? (error.error.message==="ADMIN_YET_TO_VERIFY" ? errMsg[this.selectedLanguage] :DEFINE.common_error_msg)
                : DEFINE.common_error_msg;
            this.toastr.error(finalErrMsg, "Error");
            this.spinner.hide();
          }
        );
    }
  }
  /**
   * If no manager is added in that company then redirect to manager add page. if no rating card added for that company then redirect to rating page based on selected service i.e private cleaning and window cleaning. else redirect to manager list page
   */
  goToPageBasedOnCondition(adminDetails) {
    if (
      !adminDetails || !adminDetails.company || !adminDetails.company || !adminDetails.company.company_signup_process_flag || !adminDetails.company.company_signup_process_flag.isRatingCardExist
    ) {
      
      // if (this.isPrivateCleaning) {
        this.router.navigate(["/how_it_works/video"]);
      // } else {
      //   this.router.navigate(["/my-prices/my-prices"]);
      // }
    }
    else if (!adminDetails || !adminDetails.company || !adminDetails.company || !adminDetails.company.company_signup_process_flag || !adminDetails.company.company_signup_process_flag.isManagerExist) {
      this.router.navigate(["/company/managers"]);
    }
    else {
      this.router.navigate(["/manage-management"]);
    }
  }
  /**
   * This function is used when user connect.
   */
  user_connection(id) {
    this.socket = io(environment.socketChatURL);
    this.socket.on("connect", () => {
      this.socket.emit(
        "user_join",
        { user_id: id, socket_id: this.socket.id },
        (data) => {
          console.log("RESPONSE CONNECTION:->", data);
          if (data.status == 200) {
            this.spinner.hide();
            // this.router.navigate(["/manage-management"]);
          }
        }
      );
    });
  }

  getMetaData(companyUserDetails) {
    this.adminService.getMetaData({}).subscribe(
      (response) => {
        let metaData = {
          windowCleaningID:
            response.data.services[CLEANING_POSITION[1].index].main._id,
          privateCleaningID:
            response.data.services[CLEANING_POSITION[0].index].main._id,
          otherDetails: response.data.services,
        };
        localStorage.setItem("metaData", JSON.stringify(metaData));
        console.log(
          "this.businessCardDetails",
          this.businessCardDetails.provided_services
        );
        if (
          this.businessCardDetails &&
          this.businessCardDetails.provided_services &&
          this.businessCardDetails.provided_services.length > 0
        ) {
          for (
            let i = 0;
            i < this.businessCardDetails.provided_services.length;
            i++
          ) {
            ;
            if (
              this.businessCardDetails.provided_services[i] ==
              metaData.privateCleaningID
            ) {
              this.isPrivateCleaning = true;
            }
            if (
              this.businessCardDetails.provided_services[i] ==
              metaData.windowCleaningID
            ) {
              this.isWindowCleaning = true;
            }
          }
        }
        this.goToPageBasedOnCondition(companyUserDetails)

      },
      (error) => { }
    );
  }
}
