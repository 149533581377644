import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class TranslatorService {
  public selectedLangauage;
  private availablelangs = [
    { code: "en", text: "English" },
    { code: "da", text: "Dansk" },
    { code: "ar", text: "Arabic" }
  ];

  constructor(public translate: TranslateService, private titleService : Title) {
  }
  getAvailableLanguages() {
    return this.availablelangs;
  }

  traslateTabTitle(titleFromRoutes)
  {
    let selected_language =localStorage.getItem("selectedLanguage")
    this.translate.use(selected_language);
    this.translate.get(titleFromRoutes).subscribe(
      (res: string) => {
        this.titleService.setTitle(res);
      }
    );
  }
  
}
