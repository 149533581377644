import { OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Validations } from '../../shared/helpers/Validations';
import { AdminService } from '../../shared/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { TranslatorService } from "../../shared/services/translator.service";
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(formBuilder, router, toastr, authService, translate, translator, route, translatorService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.toastr = toastr;
        this.authService = authService;
        this.translate = translate;
        this.translator = translator;
        this.route = route;
        this.translatorService = translatorService;
        this.submitted = false;
        var getSelectedLanguage = localStorage.getItem("selectedLanguage");
        if (getSelectedLanguage) {
            this.selectedLanguage = getSelectedLanguage;
            this.translate.use(getSelectedLanguage);
            if (this.selectedLanguage == 'ar') {
                var body = document.getElementsByTagName('body')[0];
                body.classList.add('body-ar');
            }
            else {
                var body = document.getElementsByTagName('body')[0];
                body.classList.remove("body-ar");
            }
        }
        else {
            this.selectedLanguage = environment.default_language;
            this.translate.setDefaultLang(environment.default_language);
        }
        this.translatorService.traslateTabTitle(this.route.snapshot.data['title']);
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.createForm();
    };
    ForgotPasswordComponent.prototype.getLangs = function () {
        return this.translator.getAvailableLanguages();
    };
    ForgotPasswordComponent.prototype.setLang = function (value) {
        //this.translator.useLanguage(value);
        this.translate.use(value);
        this.selectedLanguage = value;
        localStorage.setItem("selectedLanguage", this.selectedLanguage);
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove("body-ar");
        if (this.selectedLanguage == 'ar') {
            body.classList.add('body-ar');
        }
        else {
            body.classList.remove("body-ar");
        }
        this.translatorService.traslateTabTitle(this.route.snapshot.data['title']);
    };
    /**
     * Create forgot password reactive from for get email from user and validate form
     */
    ForgotPasswordComponent.prototype.createForm = function () {
        this.forgotPassForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(Validations.email_pattern)]]
        });
    };
    Object.defineProperty(ForgotPasswordComponent.prototype, "getControls", {
        /**
         * Getter method for get all controls of forgot password form
         */
        get: function () { return this.forgotPassForm.controls; },
        enumerable: true,
        configurable: true
    });
    /**
     * On form submit call forgot password api and on success redirect on login page
     */
    ForgotPasswordComponent.prototype.forgotPassFormSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.forgotPassForm.valid) {
            this.authService.ForgotPassword(this.forgotPassForm.value).subscribe(function (res) {
                _this.toastr.success(res.message ? res.message : 'Reset link send successfully, please check your mail.', 'Success');
                _this.router.navigate(['/login']);
            }, function (error) {
                _this.toastr.error(error.error.message, "Error");
            });
        }
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
