import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/messaging";
// import { NotificationService } from "../services/notification.service";
var MessagingService = /** @class */ (function () {
    function MessagingService(angularFireMessaging) {
        var _this = this;
        this.angularFireMessaging = angularFireMessaging;
        this.currentMessage = new BehaviorSubject(null);
        this.cuurentToken = new BehaviorSubject(null);
        this.angularFireMessaging.messaging.subscribe(function (_messaging) {
            /**
             * Show notification popup for both forground as well as back ground.
             */
            _messaging.onMessage(function (payload) {
                // this.notificationService.getNotificationCount()
                navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(function (registration) {
                    console.log('registration: ', registration);
                    registration.showNotification(payload.notification.title, payload.notification);
                    _this.currentMessage.next(payload);
                });
            });
            ;
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        });
    }
    /**
    * request permission for notification from firebase cloud messaging
    *
    * @param userId userId
    */
    MessagingService.prototype.requestPermission = function (userId) {
        this.angularFireMessaging.requestToken.subscribe(function (token) {
        }, function (err) {
            // console.error('Unable to get permission to notify.', err);
        });
    };
    /**
    * hook method when new notification received in foreground
    */
    MessagingService.prototype.receiveMessage = function () {
        this.angularFireMessaging.messages.subscribe(function (payload) {
            console.log('payload: ', payload);
        });
    };
    /**
      * request permission for notification from firebase cloud messaging
      *
      */
    MessagingService.prototype.requestToken = function () {
        var _this = this;
        this.angularFireMessaging.requestToken.subscribe(function (token) {
            _this.cuurentToken.next(token);
        }, function (err) {
            // console.error('Unable to get permission to notify.', err);
        });
    };
    MessagingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.ɵɵinject(i1.AngularFireMessaging)); }, token: MessagingService, providedIn: "root" });
    return MessagingService;
}());
export { MessagingService };
