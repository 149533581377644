import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
    }
    /**
     * This method is used for admin login
     * @param data : object of email and password of admin user
     */
    LoginService.prototype.adminLogin = function (data) {
        return this.http.post(environment.apiUrl + "auth/login", data);
    };
    /**
     * This method is used for forgot password email link send
     */
    LoginService.prototype.adminForgotLink = function (data) {
        return this.http.post(environment.apiUrl + "admin_auth/forgotPassword", data);
    };
    /**
     * This method is usd for reset password http call
     * @param data : token and new password
     */
    LoginService.prototype.adminResetPassword = function (data) {
        return this.http.post(environment.apiUrl + "admin_auth/resetPassword", data);
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
