import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PasswordValidation } from '../../shared/helpers/password-validation';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../shared/services/login.service';
import { Validations } from '../../shared/helpers/Validations';
import { DEFINE } from '../../shared/config/define';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  ///define variables
  resetPassForm: FormGroup;
  submitted: boolean = false;
  reset_token: string;
  routeSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe(res => {
      this.reset_token = res['reset_token'];
    })

    this.createForm();
  }


  /**
   * Create change password reactive from and set validate rules
   */
  createForm() {
    this.resetPassForm = this.formBuilder.group({
      new_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16), Validators.pattern(Validations.whitespace)]],
      confirm_password: ['', [Validators.required]]
    }, {
        validator: PasswordValidation.MatchPassword // your custom validation method  
      });
  }

  /**
 * Get form's control from html
 */
  get getControls() { return this.resetPassForm.controls };

  // on change password form submit handle function
  resetPassFromSubmit() {
    this.submitted = true;
    if (this.resetPassForm.valid) {
      const resetData = {
        reset_token: this.reset_token,
        password: this.resetPassForm.value.new_password
      };

      this.loginService.adminResetPassword(resetData).subscribe(res => {
        this.toastr.success('Reset password successfully.', 'Success');
        this.router.navigate(['/login']);
      }, error => {
        this.submitted = false;
        this.resetPassForm.reset();
        const finalErrMsg = error && error.error.message ? error.error.message : DEFINE.common_error_msg;
        this.toastr.error(finalErrMsg, 'Error');
      });
    }
  }

}
