import {
  Component,
  OnInit,
  AfterViewInit,
  AfterContentChecked,
  AfterViewChecked,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { LoaderService } from "./shared/services/loader.service";
import { Subscription } from "rxjs";
import { MessagingService } from "./shared/services/messaging.service";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template:
    '<router-outlet></router-outlet><ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>',
})
export class AppComponent implements OnInit {
  message;

  constructor(
    private messagingService: MessagingService
  ) {}
  ngOnInit() {
    if(localStorage.getItem('sp-user'))
    {
    let userId = JSON.parse(localStorage.getItem('sp-user'))._id;
    this.messagingService.requestPermission(userId)
    }
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    console.log('this.message: ', this.message);
  }
}
