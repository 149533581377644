import { Routes } from "@angular/router";
// Import Containers
import { DefaultLayoutComponent } from "./containers";
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { AuthGuard } from "./shared/helpers/auth-guard";
import { P403Component } from "./views/error/403.component";
import { ForgotPasswordComponent } from "./views/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./views/reset-password/reset-password.component";
var ɵ0 = {
    title: "Page 404",
}, ɵ1 = {
    title: "Page 500",
}, ɵ2 = {
    title: "Page 403",
}, ɵ3 = {
    title: "app_routing_breadcrumb.login",
}, ɵ4 = {
    title: "app_routing_breadcrumb.register",
}, ɵ5 = {
    title: "app_routing_breadcrumb.forgot_password",
}, ɵ6 = {
    title: "Forgot Password",
}, ɵ7 = function () {
    return import("./views/manage-company/manage-company.module.ngfactory").then(function (m) { return m.ManageCompanyModuleNgFactory; });
}, ɵ8 = function () { return import("./views/notification/notification.module.ngfactory").then(function (m) { return m.NotificationModuleNgFactory; }); }, ɵ9 = function () {
    return import("./views/account/account.module.ngfactory").then(function (m) { return m.AccountModuleNgFactory; });
}, ɵ10 = function () {
    return import("./views/my-prices/my-prices.module.ngfactory").then(function (m) { return m.MyPricesModuleNgFactory; });
}, ɵ11 = function () {
    return import("./views/company-management/company-management.module.ngfactory").then(function (m) { return m.CompanyManagementModuleNgFactory; });
}, ɵ12 = function () {
    return import("./views/chat/chat.module.ngfactory").then(function (m) { return m.ChatModuleNgFactory; });
}, ɵ13 = function () {
    return import("./views/chat-view/chat-view.module.ngfactory").then(function (m) { return m.ChatViewModuleNgFactory; });
}, ɵ14 = function () {
    return import("./views/edit-company/edit-company.module.ngfactory").then(function (m) { return m.ManageCompanyModuleNgFactory; });
}, ɵ15 = function () {
    return import("./views/edit-my-prices/my-prices.module.ngfactory").then(function (m) { return m.MyPricesModuleNgFactory; });
}, ɵ16 = function () {
    return import("./views/employee-management/employee-management.module.ngfactory").then(function (m) { return m.EmployeeManagementModuleNgFactory; });
}, ɵ17 = function () {
    return import("./views/manage-management/manage-management.module.ngfactory").then(function (m) { return m.ManageManagementModuleNgFactory; });
}, ɵ18 = function () {
    return import("./views/manage-wallet/manage-wallet.module.ngfactory").then(function (m) { return m.ManageWalletModuleNgFactory; });
}, ɵ19 = function () {
    return import("./views/tearms-and-condition/tearms-and-condition.module.ngfactory").then(function (m) { return m.TearmsAndConditionModuleNgFactory; });
}, ɵ20 = function () {
    return import("./views/standard-cleaning/list.module.ngfactory").then(function (m) { return m.ListModuleNgFactory; });
}, ɵ21 = function () {
    return import("./views/login_video/video.module.ngfactory").then(function (m) { return m.VideoModuleNgFactory; });
}, ɵ22 = function () {
    return import("./views/product-management/product-management.module.ngfactory").then(function (m) { return m.ProductManagementModuleNgFactory; });
}, ɵ23 = function () {
    return import("./views/interest-management/interest-management.module.ngfactory").then(function (m) { return m.InterestManagementModuleNgFactory; });
}, ɵ24 = function () {
    return import("./views/custom-order/custom-order.module.ngfactory").then(function (m) { return m.CustomOrderModuleNgFactory; });
};
export var routes = [
    {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
    },
    {
        path: "404",
        component: P404Component,
        data: ɵ0,
    },
    {
        path: "500",
        component: P500Component,
        data: ɵ1,
    },
    {
        path: "403",
        component: P403Component,
        data: ɵ2,
    },
    {
        path: "login",
        component: LoginComponent,
        data: ɵ3,
    },
    {
        path: "register",
        component: RegisterComponent,
        data: ɵ4,
    },
    {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        data: ɵ5,
    },
    {
        path: "reset-password/:reset_token",
        component: ResetPasswordComponent,
        data: ɵ6,
    },
    {
        path: "",
        // component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "",
                redirectTo: "zipcode-group",
                pathMatch: "full",
            },
            {
                path: "company",
                loadChildren: ɵ7,
            },
            {
                path: 'notification',
                component: DefaultLayoutComponent,
                loadChildren: ɵ8
            },
            {
                path: "account",
                component: DefaultLayoutComponent,
                loadChildren: ɵ9,
            },
            {
                path: "my-prices",
                loadChildren: ɵ10,
            },
            {
                path: "company-management",
                component: DefaultLayoutComponent,
                loadChildren: ɵ11,
            },
            {
                path: "chat",
                component: DefaultLayoutComponent,
                loadChildren: ɵ12,
            },
            {
                path: "chat-view",
                component: DefaultLayoutComponent,
                loadChildren: ɵ13,
            },
            {
                path: "edit-company",
                // component: DefaultLayoutComponent,
                loadChildren: ɵ14,
            },
            {
                path: "edit-rating-card",
                loadChildren: ɵ15,
            },
            {
                path: "employee-management",
                component: DefaultLayoutComponent,
                loadChildren: ɵ16,
            },
            {
                path: "manage-management",
                component: DefaultLayoutComponent,
                loadChildren: ɵ17,
            },
            {
                path: "manage-wallet",
                component: DefaultLayoutComponent,
                loadChildren: ɵ18,
            },
            {
                path: "terms-and-condition",
                component: DefaultLayoutComponent,
                loadChildren: ɵ19,
            },
            {
                path: "standard-cleaning",
                component: DefaultLayoutComponent,
                loadChildren: ɵ20,
            },
            {
                path: "how_it_works",
                // component: DefaultLayoutComponent,
                loadChildren: ɵ21,
            },
            {
                path: "product-management",
                component: DefaultLayoutComponent,
                loadChildren: ɵ22,
            },
            {
                path: "interest-management",
                component: DefaultLayoutComponent,
                loadChildren: ɵ23,
            },
            {
                path: "custom-order",
                //component: DefaultLayoutComponent,
                loadChildren: ɵ24,
            },
        ],
    },
    { path: "**", component: P404Component },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24 };
