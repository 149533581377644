import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private isLoading = new BehaviorSubject(false);
  currentMessage = this.isLoading.asObservable();

  constructor() { }

  changeValue(val: boolean) {
    this.isLoading.next(val);
  }
  getValue(){
    return this.isLoading;
  }
}
