import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { AuthGuard } from "./shared/helpers/auth-guard";
import { NgxPermissionsGuard } from "ngx-permissions";
import { P403Component } from "./views/error/403.component";
import { ForgotPasswordComponent } from "./views/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./views/reset-password/reset-password.component";

export const routes: Routes = [
    {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
    },
    {
        path: "404",
        component: P404Component,
        data: {
            title: "Page 404",
        },
    },
    {
        path: "500",
        component: P500Component,
        data: {
            title: "Page 500",
        },
    },
    {
        path: "403",
        component: P403Component,
        data: {
            title: "Page 403",
        },
    },
    {
        path: "login",
        component: LoginComponent,
        data: {
            title: "app_routing_breadcrumb.login",
        },
    },
    {
        path: "register",
        component: RegisterComponent,
        data: {
            title: "app_routing_breadcrumb.register",
        },
    },
    {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        data: {
            title: "app_routing_breadcrumb.forgot_password",
        },
    },
    {
        path: "reset-password/:reset_token",
        component: ResetPasswordComponent,
        data: {
            title: "Forgot Password",
        },
    },
    {
        path: "",
        // component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "",
                redirectTo: "zipcode-group",
                pathMatch: "full",
            },
            {
                path: "company",
                loadChildren: () =>
                    import("./views/manage-company/manage-company.module").then(
                        (m) => m.ManageCompanyModule
                    ),
            },
            {
                path: 'notification',
                component: DefaultLayoutComponent,
                loadChildren: () => import('./views/notification/notification.module').then(m => m.NotificationModule)
              },
            {
                path: "account",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/account/account.module").then((m) => m.AccountModule),
            },
            {
                path: "my-prices",
                loadChildren: () =>
                    import("./views/my-prices/my-prices.module").then(
                        (m) => m.MyPricesModule
                    ),
            },
            {
                path: "company-management",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/company-management/company-management.module").then(
                        (m) => m.CompanyManagementModule
                    ),
            },
            {
                path: "chat",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/chat/chat.module").then(
                        (m) => m.ChatModule
                    ),
            },
            {
                path: "chat-view",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/chat-view/chat-view.module").then(
                        (m) => m.ChatViewModule
                    ),
            },
            {
                path: "edit-company",
                // component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/edit-company/edit-company.module").then(
                        (m) => m.ManageCompanyModule
                    ),
            },
            {
                path: "edit-rating-card",
                loadChildren: () =>
                    import("./views/edit-my-prices/my-prices.module").then(
                        (m) => m.MyPricesModule
                    ),
            },
            {
                path: "employee-management",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/employee-management/employee-management.module").then(
                        (m) => m.EmployeeManagementModule
                    ),
            },
            {
                path: "manage-management",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/manage-management/manage-management.module").then(
                        (m) => m.ManageManagementModule
                    ),
            },
            {
                path: "manage-wallet",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/manage-wallet/manage-wallet.module").then(
                        (m) => m.ManageWalletModule
                    ),
            },
            {
                path: "terms-and-condition",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/tearms-and-condition/tearms-and-condition.module").then(
                        (m) => m.TearmsAndConditionModule
                    ),
            },
            {
                path: "standard-cleaning",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/standard-cleaning/list.module").then(
                        (m) => m.ListModule
                    ),
            },
            {
                path: "how_it_works",
                // component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/login_video/video.module").then(
                        (m) => m.VideoModule
                    ),
            },
            {
                path: "product-management",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/product-management/product-management.module").then(
                        (m) => m.ProductManagementModule
                    ),
            },
            {
                path: "interest-management",
                component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/interest-management/interest-management.module").then(
                        (m) => m.InterestManagementModule
                    ),
            },
            {
                path: "custom-order",
                //component: DefaultLayoutComponent,
                loadChildren: () =>
                    import("./views/custom-order/custom-order.module").then(
                        (m) => m.CustomOrderModule
                    ),
            },
        ],
    },
    { path: "**", component: P404Component },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
