import { OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { PasswordValidation } from '../../shared/helpers/password-validation';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../shared/services/login.service';
import { Validations } from '../../shared/helpers/Validations';
import { DEFINE } from '../../shared/config/define';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(formBuilder, router, route, toastr, loginService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.toastr = toastr;
        this.loginService = loginService;
        this.submitted = false;
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.routeSubscription = this.route.params.subscribe(function (res) {
            _this.reset_token = res['reset_token'];
        });
        this.createForm();
    };
    /**
     * Create change password reactive from and set validate rules
     */
    ResetPasswordComponent.prototype.createForm = function () {
        this.resetPassForm = this.formBuilder.group({
            new_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16), Validators.pattern(Validations.whitespace)]],
            confirm_password: ['', [Validators.required]]
        }, {
            validator: PasswordValidation.MatchPassword // your custom validation method  
        });
    };
    Object.defineProperty(ResetPasswordComponent.prototype, "getControls", {
        /**
       * Get form's control from html
       */
        get: function () { return this.resetPassForm.controls; },
        enumerable: true,
        configurable: true
    });
    ;
    // on change password form submit handle function
    ResetPasswordComponent.prototype.resetPassFromSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.resetPassForm.valid) {
            var resetData = {
                reset_token: this.reset_token,
                password: this.resetPassForm.value.new_password
            };
            this.loginService.adminResetPassword(resetData).subscribe(function (res) {
                _this.toastr.success('Reset password successfully.', 'Success');
                _this.router.navigate(['/login']);
            }, function (error) {
                _this.submitted = false;
                _this.resetPassForm.reset();
                var finalErrMsg = error && error.error.message ? error.error.message : DEFINE.common_error_msg;
                _this.toastr.error(finalErrMsg, 'Error');
            });
        }
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
