
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  private api_url = `${environment.apiUrl}admin/`;
  public logo_url 
  constructor(
    private http: HttpClient
  ) {}

  addUserDetails(data: any) {
    return this.http.post<any>(environment.apiUrl + "api/add_user_details", data);
  }
  businessCardDetails(data: any) {
    return this.http.post<any>(environment.apiUrl + "api/business_card_details", data);
  }
  sendMail(data: any) {
    return this.http.post<any>(environment.apiUrl + "api/send_mail", data);
  }
  storeLogo(fileObj)
  {
    this.logo_url =fileObj
  }
}