import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { AdminService } from "../../shared/services/admin.service";
import { Validations } from "../../shared/helpers/Validations";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { AUTH, DEFINE, CLEANING_POSITION } from "../../shared/config/define";
import { TranslatorService } from "../../shared/services/translator.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "register.component.html",
})
export class RegisterComponent {
  loginForm: FormGroup;
  isSubmitted: boolean = false;
  selectedLanguage: string;
  tearmsAndConditionURLBasedOnSelectedLanguage : string
  isReadTearmsAndCondition:Boolean = false
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AdminService,
    private translate: TranslateService,
    private route:ActivatedRoute,
    private translatorService:TranslatorService
  ) {
    let getSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (getSelectedLanguage) {
      this.selectedLanguage = getSelectedLanguage;
      this.translate.use(getSelectedLanguage);
      if(this.selectedLanguage == 'ar'){
        let body = document.getElementsByTagName('body')[0];    
        body.classList.add('body-ar');
        this.tearmsAndConditionURLBasedOnSelectedLanguage ="https://easyr.dk/%d8%a3%d8%ad%d9%83%d8%a7%d9%85-%d9%88%d8%b4%d8%b1%d9%88%d8%b7/"
      }
      else{
        let body = document.getElementsByTagName('body')[0];    
        body.classList.remove("body-ar");
      }
    } else {
      this.translate.setDefaultLang(environment.default_language);
      this.selectedLanguage = environment.default_language
      this.tearmsAndConditionURLBasedOnSelectedLanguage ="https://easyr.dk/terms-condition/"
    }
    if(this.selectedLanguage == "da")
    {
      this.tearmsAndConditionURLBasedOnSelectedLanguage = "https://easyr.dk/vilkar-betingelser/"
    }
    if(this.selectedLanguage == "en")
    {
      this.tearmsAndConditionURLBasedOnSelectedLanguage ="https://easyr.dk/terms-condition/"
    }
    this.translatorService.traslateTabTitle(this.route.snapshot.data['title'])
  }
  ngOnInit(): void {
    this.formForRegistration();
  }
  formForRegistration() {
    this.loginForm = new FormGroup({
      firstName: new FormControl("", [
        Validators.required,
        Validators.pattern(Validations.name_pattern),
      ]),
      lastName: new FormControl("", [
        Validators.required,
        Validators.pattern(Validations.name_pattern),
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(Validations.email_pattern),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(16),
      ]),
      mobile_number: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(10),
      ]),
      tearmsCheckbox: new FormControl(null, [
        Validators.required
      ]),
    });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  onRegistrationFormSubmit() {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append("firstName", this.loginForm.value.firstName);
      formData.append("lastName", this.loginForm.value.lastName);
      formData.append("email", this.loginForm.value.email);
      formData.append("password", this.loginForm.value.password);
      formData.append("mobile_number", this.loginForm.value.mobile_number);
      formData.append("user_type", "3");
      formData.append("fcm_token", "fcmtoken");
      formData.append("device_id", "web");
      formData.append("gender", "1");
      formData.append("address", "");
      formData.append("receiver_zipcode", "");
      formData.append("city", "");
      formData.append("floor", "");
      formData.append("apartment_number", "");
      formData.append("above_18", "true");
      formData.append("lat", "0.0");
      formData.append("lng", "0.0");

      this.authService.register(formData).subscribe(
        (response) => {
          this.isSubmitted = false;
          localStorage.clear();
          this.spinner.hide();
          // this.toastr.success(response["message"], "Success");
          this.getMetaData();
          const adminDetails = response.data ? response.data : null;
          if (adminDetails) {
            localStorage.setItem(
              AUTH.TOKEN_KEY,
              adminDetails.device_details[0].access_token
            );
            localStorage.setItem(
              AUTH.USER_DETAIL,
              JSON.stringify(adminDetails)
            );
            localStorage.setItem("is_login", JSON.stringify(false));
            localStorage.setItem("selectedLanguage", this.selectedLanguage);

            // set permission list
            this.router.navigate(["/company/my-company"]);
          } else {
            const finalErrMsg = DEFINE.common_error_msg;
            this.toastr.error(finalErrMsg, "Error");
          }
        },
        (error) => {
          this.isSubmitted = false;
          this.spinner.hide();
          this.toastr.error(error.error.message, "Error");
        }
      );
    }
  }
  getMetaData() {
    this.authService.getMetaData({}).subscribe(
      (response) => {
        let metaData = {
          windowCleaningID:
            response.data.services[CLEANING_POSITION[1].index].main._id,
          privateCleaningID:
            response.data.services[CLEANING_POSITION[0].index].main._id,
          otherDetails: response.data.services,
        };
        localStorage.setItem("metaData", JSON.stringify(metaData));
      },
      (error) => {}
    );
  }
}
