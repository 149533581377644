<!-- <app-header [navbarBrandRouterLink]="['/manage-management']" [fixed]="true"
    [navbarBrandFull]="{src: 'assets/favicon.png', alt: 'EasyR'}"
    [navbarBrandMinimized]="{src: 'assets/favicon.png', width: 30, height: 30, alt: 'EasyR'}" [sidebarToggler]="'lg'"
    [asideMenuToggler]="'lg'"> -->
<app-header [navbarBrandRouterLink]="['/manage-management']" [fixed]="true"
    [navbarBrandFull]="{src: 'assets/favicon.png', alt: 'EasyR'}"
    [navbarBrandMinimized]="{src: 'assets/favicon.png', width: 30, height: 30, alt: 'EasyR'}" [sidebarToggler]="false"
    [asideMenuToggler]="false">
    <div class="ml-auto language-box">
            <select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
                <option [value]="lang.code" *ngFor="let lang of getLangs()" selected="danish">{{lang.text}}</option>
            </select>
    </div>
    <!-- <select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
        <option [value]="lang.code" *ngFor="let lang of getLangs()">{{lang.text}}</option>
    </select> -->
    <ul class="nav navbar-nav ml-2">
        <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link image-box" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
                dropdownToggle (click)="false">
                <span class="ml-4 text-dark">{{ "settings.profile" | translate}}</span>
                <img src="assets/defualtimg.png" class="img-avatar" alt="admin@bootstrapmaster.com" />
                <i class="icon-arrow-down mt-5"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <!-- <a class="dropdown-item" [routerLink]="['/profile']"><i class="fa fa-user"></i> Profile</a> -->
                <!-- <a class="dropdown-item" [routerLink]="['/company/my-company']"><i class="fa fa-building"></i> {{
                    "settings.edit_company" | translate }}</a>
                <a class="dropdown-item" (click)="goToPricePage()" *ngIf="isPrivateCleaning || isWindowCleaning"><i
                        class="fa fa-money"></i>{{ "settings.edit_prices" | translate }}</a> -->
                <a class="dropdown-item" [routerLink]="['/account/view-profile']"><i class="fa fa-user"></i>{{ "settings.view_profile" | translate
                    }}</a>            
                <a class="dropdown-item" [routerLink]="['/account/change-password']"><i class="fa fa-key"></i>{{
                    "settings.change_password" | translate }}</a>
                <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i>{{ "settings.logout" | translate
                    }}</a>
                
            </div>
        </li>
    </ul>
</app-header>
<div class="app-body">
    <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
        (minimizedChange)="toggleMinimize($event)">
        <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
        <!-- <app-sidebar-minimizer></app-sidebar-minimizer> -->
    </app-sidebar>
    <!-- Main content -->
    <main class="main">
        <app-custom-breadcrumb>
        </app-custom-breadcrumb>
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div><!-- /.container-fluid -->
    </main>
</div>

<!-- Change password Modal -->
<ng-template #changepassword>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Change Password</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="view-customer-details">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <p>To reset your password please enter your current and new password</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Current Password">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="New password">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Confirm Password">
                    </div>
                </div>
                <div class="col-md-12">
                    <a href="javascript:void(0)" (click)="modalRef.hide()" class="btn btn-primary">Submit</a>
                </div>
            </div>
        </div>
    </div>
</ng-template>